import React from 'react';
import { Col, Modal, Row, Button } from 'react-bootstrap';
import QRCode from 'react-qr-code';

const ModalPin = (props)=>{
    return(
        <Modal centered show={props.show} onHide={props.toggle} className="modal-pin">
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className='text-center'>
                <h4>Scan and access your Giftano Card from your mobile device!</h4>
                <div className="qr">
                    {props.url && (
                        <QRCode value={props.url} size={200}/>
                    )}
                </div>
                <div className="box-pin">
                    <Row as={'dl'}>
                        <Col as={'dt'} sm="6" >Giftano Card Number</Col>
                        <Col as={'dd'} sm="6" >{props.number.substr(0,4)+" "+props.number.substr(4,4)+" "+props.number.substr(8,4)+" "+props.number.substr(12) }</Col>
                        {props.pin && (
                            <React.Fragment>
                                <Col as={'dt'} sm="6" >Giftano Card Pin</Col>
                                <Col as={'dd'} sm="6" >{props.pin}</Col>
                            </React.Fragment>
                        )}
                    </Row>
                </div>
                <Button className="mt-2" color="primary" onClick={props.toggle}>OK</Button>
            </Modal.Body>
        </Modal>
    )
}

export default ModalPin;