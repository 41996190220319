import React, { useContext, useEffect, useState } from 'react'
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom'
import { AppContext, AppContextProvider } from './states'
import './App.scss'

import Loading from './pages/Loading'
import GetStarted from './pages/get-started/GetStarted'
// import Passcode from './pages/Passcode'
// import Start from './pages/Start'
import Merchant from './pages/merchant/Merchant'
import Search from './pages/search/Search'
import MyGifts from './pages/my-gifts/MyGifts'

import { loadReCaptcha } from 'react-recaptcha-v3'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import 'react-phone-input-2/lib/style.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import ModalTopUp from './pages/ModalTopUp'
import ModalGreetingCard from './pages/ModalGreetingCard'
import ModalHelp from './pages/ModalHelp'
import ModalGiftType from './components/ModalGiftType'
import Axios from 'axios'
import { Suspense } from 'react'
import Lottie from 'react-lottie-player'
import lottieFile from './assets/lf30_shkzz4pp.json'
import { getContrastYIQ } from './components/ContrastColor'
import { Connection } from './services/Connection'

import GA4React from "ga-4-react";
import { FaSpinner } from 'react-icons/fa'

const ga4react = new GA4React('G-4MYPYSYSE8')

// const Loading = React.lazy(() => import('./pages/Loading'));
const Passcode = React.lazy(() => import('./pages/Passcode'))
const Start = React.lazy(() => import('./pages/Start'))
const Account = React.lazy(() => import('./pages/Account'))
const Faqs = React.lazy(() => import('./pages/faqs/Faqs'))
const HowToRedeem = React.lazy(() => import('./pages/HowToRedeem'))
// const GetStarted = React.lazy(() => import('./pages/get-started/GetStarted'));
// const Search = React.lazy(() => import('./pages/Search'));
// const Merchant = React.lazy(() => import('./pages/Merchant'));
// const MyGifts = React.lazy(() => import('./pages/MyGifts'));

const params = new URLSearchParams(window.location.search)
let session_id = localStorage.getItem('session_id')
if (params.has('session_id')) {
  session_id = atob(params.get('session_id'))
}

// import ReactGA from 'react-ga';
// ReactGA.initialize('272553279');

function shadeColor(color, percent) {
  var R = parseInt(color.substring(1, 3), 16)
  var G = parseInt(color.substring(3, 5), 16)
  var B = parseInt(color.substring(5, 7), 16)

  R = parseInt((R * (100 + percent)) / 100)
  G = parseInt((G * (100 + percent)) / 100)
  B = parseInt((B * (100 + percent)) / 100)

  R = R < 255 ? R : 255
  G = G < 255 ? G : 255
  B = B < 255 ? B : 255

  var RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16)
  var GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16)
  var BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16)

  return '#' + RR + GG + BB
}

function Layout() {
  const { state, dispatch } = useContext(AppContext)
  const [isLogin, setIsLogin] = useState(true)

  useEffect(() => {
    if (session_id) {
      //check is session valid
      Connection({
        url: 'profile/get/member',
        headers: {
          session_id: session_id,
        },
      })
        .then((profileData) => {
          // console.log('profile data', profileData)
          if (
            profileData.code === 200 &&
            profileData.data.member &&
            profileData.data.member.id
          ) {
            //save session and continue
            localStorage.setItem('session_id', session_id)
            dispatch({
              type: 'SET_SESSION_ID',
              data: session_id,
              member: profileData.data.member,
            })
            setIsLogin(false)
          } else {
            // try create member profile automaticaly
            Connection({
              url: 'member/profile',
              method: 'POST',
              headers: {
                session_id: session_id,
              },
              body: {
                without_send_email: true,
              },
            })
              .then((resCreate) => {
                localStorage.setItem('session_id', session_id)
                dispatch({
                  type: 'SET_SESSION_ID',
                  data: session_id,
                  member: {},
                })
                setIsLogin(false)
              })
              .catch((err) => {
                console.log(err)
              })

            // toast.warning('Failed get member profile, please try again or contact us if this problem persists!', {
            //   onClose: ()=>{
            //     const redirect_auth = process.env.REACT_APP_REDIRECT_AUTH;
            //     const locationWindow = window.location.protocol + '//' + window.location.host + window.location.pathname + "?";
            //     const redirect_url = redirect_auth + "?redirect=" + btoa(locationWindow + "|member") + "&rb=" + btoa(window.location.origin + '/getstarted') + '&mode=3' ;
            //     window.location = redirect_url;
            //   }
            // });
          }
        })
        .catch((err) => {
          // const redirect_auth = process.env.REACT_APP_REDIRECT_AUTH;
          // const locationWindow = window.location.protocol + '//' + window.location.host + window.location.pathname + "?";
          // const redirect_url = redirect_auth + "?redirect=" + btoa(locationWindow + "|member") + "&rb=" + window.location.origin + '/getstarted&mode=3';
          // window.location = redirect_url;
          localStorage.removeItem('session_id')
          dispatch({ type: 'SET_SESSION_ID', data: '', member: null })
          setIsLogin(false)
        })
    } else {
      setIsLogin(false)
    }
  }, [dispatch])

  // fetch from strapi
  useEffect(() => {
    let loadContent = async () => {
      try {
        // let res = await Axios.get(
        //   '/content/giftano-card-redemption'
        // )
        let res = await Axios.get(
          'https://giftano.com/webcontent/giftano-card-redemption'
        )
        if (res.status === 200) {
          dispatch({ type: 'SET_STRAPI_DATA', data: res.data })

          // init analytics if enabled
          if (res.data.google_analytics) {
            try {
              await ga4react.initialize()
            } catch {}
          }
        }
      } catch (err) {
        console.log('error get content', err)
      }
    }
    loadContent()
  }, [dispatch])

  // console.log('router state', state)
  useEffect(() => {
    if (state.whitelabel) {
      let root = document.documentElement
      let primaryColor =
        state.whitelabel.settings['giftano-card-redemption-site-primary-color']
          .value
      if (primaryColor) {
        root.style.setProperty('--primary', primaryColor)
        root.style.setProperty(
          '--primary-contrast',
          getContrastYIQ(primaryColor.replace('#', ''))
        )
      }
      root.style.setProperty(
        '--primary-darker',
        shadeColor(
          state.whitelabel.settings[
            'giftano-card-redemption-site-primary-color'
          ].value,
          -40
        )
      )
      root.style.setProperty(
        '--primary-lighter',
        shadeColor(
          state.whitelabel.settings[
            'giftano-card-redemption-site-primary-color'
          ].value,
          90
        )
      )
      root.style.setProperty(
        '--background-logo-url',
        'url("' + (state.whitelabel.settings['giftano-card-redemption-site-logo']
          ? state.whitelabel.settings['giftano-card-redemption-site-logo'].value
          : '') + '")'
      )
      root.style.setProperty(
        '--backdrop-image',
        'url("' +
          state.whitelabel.settings['animation-backdrop-image'].value +
          '")'
      )
      root.style.setProperty(
        '--font-color',
        state.whitelabel.settings['animation-greeting-card-font-color'].value
      )
      root.style.setProperty(
        '--font-family',
        `"${state.whitelabel.settings['animation-greeting-card-font-family'].value}"`
      )
      root.style.setProperty(
        '--font-size',
        state.whitelabel.settings['animation-greeting-card-font-size'].value
      )

      root.style.setProperty(
        '--gift-box-title-color',
        state.whitelabel.settings['gift-box-title-color']
          ? state.whitelabel.settings['gift-box-title-color'].value
          : '#028C80'
      )

      let navBGColor = primaryColor
      if (
        state.whitelabel.settings[
          'giftano-card-redemption-nav-background-color'
        ]
      ) {
        navBGColor =
          state.whitelabel.settings[
            'giftano-card-redemption-nav-background-color'
          ].value
      }
      root.style.setProperty(
        '--giftano-card-redemption-nav-background-color',
        navBGColor
      )

      let navFGColor = getContrastYIQ(primaryColor)
      if (state.whitelabel.settings['giftano-card-redemption-nav-font-color']) {
        navFGColor =
          state.whitelabel.settings['giftano-card-redemption-nav-font-color']
            .value
      }
      root.style.setProperty(
        '--giftano-card-redemption-nav-font-color',
        navFGColor
      )

      // root.style.setProperty(
      //   '--google-fonts',
      //   `"https://fonts.googleapis.com/css2?family=${state.whitelabel.settings['animation-greeting-card-font-family'].value}:wght@400;700&display=swap"`
      // )

      // ... more apply var css

      // apply font here ---

      var cssId = 'fontAnim' // you could encode the css path itself to generate id..
      if (!document.getElementById(cssId)) {
        var head = document.getElementsByTagName('head')[0]
        var link = document.createElement('link')
        var style = document.createElement('style')
        link.id = cssId
        link.rel = 'stylesheet'
        link.type = 'text/css'
        link.href =
          'https://fonts.googleapis.com/css2?family=' +
          state.whitelabel.settings['animation-greeting-card-font-family']
            .value +
          ':wght@100;300;400;700&display=swap'
        link.media = 'all'
        style.innerHTML =
          state.whitelabel.settings['animation-custom-css'].value

        head.appendChild(link)
        head.appendChild(style)
      }

      // end font
    }
  }, [state.whitelabel])

  // link test
  // /redeem/8f6cef42234b37089d037e1d46bf22f8a0de3d1d/c16f212376d5e8d35856f30baf18fced
  // id white label giftano GS9QUJSM8HA8

  if(isLogin) return (
    <div className='d-flex justify-content-center align-items-center' style={{height: '100vh', width: '100%', color: 'gray', fontSize: '28px'}}>
      <div className='text-center'>
        <FaSpinner className="icon-spin mr-2"/> Authenticating...
      </div>
    </div>
  );

  return (
    <Router>
      <Suspense
        fallback={
          <Lottie
            loop
            animationData={lottieFile}
            play
            style={{ width: '100%', height: '100%' }}
          />
        }
      >
        <Switch>
          <Route path="/" exact component={Loading} />
          <Route path="/redeem/:gcard_number" exact component={Loading} />
          <Route
            path="/redeem/:gcard_number/:gcard_pin"
            exact
            component={Loading}
          />
          <Route path="/start" exact component={Start} />
          <Route path="/passcode" exact component={Passcode} />
          <Route path="/getstarted" exact component={GetStarted} />
          <Route path="/search" exact component={Search} />
          <Route path="/search/:tagId" exact component={Search} />
          <Route path="/merchant/:merchantId" exact component={Merchant} />
          <Route path="/gift/:giftId" exact component={Merchant} />
          <Route path="/mygifts" exact component={MyGifts} />
          <Route path="/mygifts/:order_id" exact component={MyGifts} />
          <Route path="/account" exact component={Account} />
          <Route path="/faqs" exact component={Faqs} />
          <Route path="/how-to-redeem" exact component={HowToRedeem} />
          <Route
            exact
            path="*"
            render={() => {
              return state === '' ? (
                <Redirect to="/passcode" />
              ) : (
                <Redirect to="/" />
              )
            }}
          />
          <Redirect from="*" to="/" />
        </Switch>
      </Suspense>
    </Router>
  )
}

function App() {
  useEffect(() => {
    loadReCaptcha('6LdJ3agZAAAAAP5uOU9jzl_Q3fJ1Q36xqdY9DNjc', () => {
      // console.log('recaptcha loaded')
    })
  }, [])
  return (
    <div className="App">
      <AppContextProvider>
        <ToastContainer />
        <ModalHelp />
        <ModalTopUp />
        <ModalGreetingCard />
        <ModalGiftType />
        <Layout />
      </AppContextProvider>
    </div>
  )
}

export default App
