/* eslint-disable eqeqeq */
import React, { useContext, useState } from 'react'
import { AppContext } from '../../../states'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Moment from 'react-moment'
import { PostPublic } from '../../../services/Connection'
import ModalPin from './ModalPin'
import { FaSpinner } from 'react-icons/fa'
import QRCode from 'react-qr-code'
import { useHistory } from 'react-router-dom'
// import { Link } from 'react-router-dom'
// import { MdMailOutline } from 'react-icons/md'
// import Clamp from 'react-multiline-clamp'

const Hero = () => {
  const [modalPin, setModalPin] = useState({ show: false })
  const modalPinToggle = () => setModalPin({ show: false })
  const [isFetchingPin, setIsFetchingPin] = useState(false)
  const { state } = useContext(AppContext)
  // eslint-disable-next-line eqeqeq
  const hidePrice = state.ecard && state.ecard.hide_price == 1 ? true : false
  const {
    number,
    merchantname,
    balance,
    expirydate,
    firstname,
    lastname,
    url,
  } = state.ecard || {}
  const titleWelcomeMessage =
    state.whitelabel &&
    state.whitelabel.settings &&
    state.whitelabel.settings['gift-box-title-welcome-message']
      ? state.whitelabel.settings['gift-box-title-welcome-message'].value
      : ''
  const history = useHistory();
  return (
    <>
      {modalPin.show && (
        <ModalPin
          show={true}
          toggle={modalPinToggle}
          url={modalPin.url}
          number={modalPin.number}
          pin={modalPin.pin}
        />
      )}
      <Container>
        <Row>
          <Col className="hero-image-container" md="7" sm="6">
            <div className="hero">
              <div className="hero-bg"></div>
              {state.ecard.is_gift_box == 1 ? (
                <>
                  <img
                    className="hero-image d-none d-sm-block"
                    src={
                      state.whitelabel.settings['gift-box-homepage-banner']
                        .value
                    }
                    alt="Giftano Gift Box"
                  />
                  <img
                    className="hero-image d-block d-sm-none"
                    src={
                      state.whitelabel.settings[
                        'gift-box-homepage-banner-mobile'
                      ].value
                    }
                    alt="Giftano Gift Box"
                  />
                </>
              ) : (
                <>
                  <img
                    className="hero-image d-none d-md-block"
                    src={
                      state.whitelabel.settings['giftano-card-homepage-banner']
                        .value
                    }
                    alt="Giftano Card"
                  />
                  <img
                    className="hero-image d-none d-sm-block d-md-none"
                    src={
                      state.whitelabel.settings[
                        'giftano-card-homepage-banner-tablet'
                      ].value
                    }
                    alt="Giftano Card"
                  />
                  <img
                    className="hero-image d-block d-sm-none"
                    src={
                      state.whitelabel.settings[
                        'giftano-card-homepage-banner-mobile'
                      ].value
                    }
                    alt="Giftano Card"
                  />
                </>
              )}
              <img
                className="plane"
                src="https://static-cdn.giftano.com/assets/gift-card/plane.png"
                alt="Giftano Card"
              />
              {state.ecard.is_gift_box == 1 ? (
                <div className="gift-card gift-box">
                  <img
                    src={
                      state.whitelabel.settings['gift-box-product-icon'].value
                    }
                    alt="Gift Box Icon"
                  />
                </div>
              ) : (
                <div
                  className="gift-card"
                  style={{
                    backgroundImage:
                      'url(' +
                      (state.whitelabel.settings['giftano-card-background']
                        ? state.whitelabel.settings['giftano-card-background']
                            .value
                        : 'https://giftano.com/img/giftano-card-email-output-email.png') +
                      ')',
                  }}
                >
                  {!hidePrice && (
                    <span className="member-balance">
                      S${balance.toLocaleString('en')}
                    </span>
                  )}
                  <span className="member-name">
                    {merchantname}
                    <br />
                    {firstname} {lastname && lastname}
                  </span>
                  <span className="member-expired">
                    Valid until{' '}
                    <Moment date={expirydate} format="DD-MMM-YYYY" />
                    {state.ecard.status === 'expired' && (
                      <strong className="text-danger ml-2">EXPIRED</strong>
                    )}
                  </span>
                  <span className="label-not-for-resale">NOT FOR RESALE</span>
                </div>
              )}
            </div>
          </Col>
          <Col className="hero-text-container" md="5" sm="6">
            <div className="hero-title">
              {state.ecard.is_gift_box == 1 ? (
                <>
                  <img
                    className="image-title-gift-box"
                    src={
                      state.whitelabel.settings['gift-box-product-icon'].value
                    }
                    alt={'Gift Box Icon'}
                  />
                  <h5>ULTIMATE GIFT</h5>
                  <h1 className="title title-gift-box">{state.ecard.value}</h1>
                </>
              ) : (
                <h1 className="title">
                  Welcome to your <br /> <span>Giftano Gift Card</span>
                </h1>
              )}
              {/* <Clamp
                withTooltip
                withToggle
                lines={2}
                showMoreElement={({toggle})=>(
                  <Button size="sm" variant="link" style={{color: 'var(--gf-primary-300)', padding: '0'}} onClick={toggle}>More</Button>
                )}
                showLessElement={({toggle})=>(
                  <Button size="sm" variant="link" style={{color: 'var(--gf-primary-300)', padding: '0'}} onClick={toggle}>Less</Button>
                )}
                > */}
              <p className="mb-3">{titleWelcomeMessage}</p>
              {/* </Clamp> */}
              <p style={{ fontWeight: 'bold', marginTop: '8px' }}>
                Click the button below to get started!
              </p>
              <div className="mt-4">
                <div
                  className="mobile-text "
                  onClick={() => {
                    if(state.ecard.is_gift_box == 1){
                      let element = document.getElementById('inspiredSection')
                      if (!element) {
                        element = document.getElementById('findYourGift')
                      }
                      if (element) {
                        // element.scrollIntoView({ behavior: 'smooth' });
                        const headerOffset = 95
                        const elementPosition =
                          element.getBoundingClientRect().top
                        const offsetPosition =
                          elementPosition + window.pageYOffset - headerOffset

                        window.scrollTo({
                          top: offsetPosition,
                          behavior: 'smooth',
                        })
                      }
                    }else{
                      history.push('/search')
                    }
                  }}
                >
                  Get Started
                </div>
                <Button
                  className="btn-get-started desktop"
                  onClick={() => {
                    if(state.ecard.is_gift_box == 1){
                      let element = document.getElementById('inspiredSection')
                      if (!element) {
                        element = document.getElementById('findYourGift')
                      }
                      if (element) {
                        // element.scrollIntoView({ behavior: 'smooth' });
                        const headerOffset = 95
                        const elementPosition =
                          element.getBoundingClientRect().top
                        const offsetPosition =
                          elementPosition + window.pageYOffset - headerOffset

                        window.scrollTo({
                          top: offsetPosition,
                          behavior: 'smooth',
                        })
                      }
                    }else{
                      history.push('/search')
                    }
                  }}
                >
                  Get Started
                </Button>
                {/* <Link
                  to="/start"
                  className="btn btn-env ml-3"
                  alt="replay animation"
                  title="replay animation"
                >
                  <MdMailOutline size="24px" className="mr-1" />
                </Link> */}
              </div>
            </div>
          </Col>
        </Row>
        

        {state.ecard.is_gift_box == 0 && (
          <div
            className="d-none d-md-flex pt-3 pb-3 justify-content-end"
            style={{
              borderTop: '1px solid var(--gf-gray-200, #ECECED)',
              borderBottom: '1px solid var(--gf-gray-200, #ECECED)',
            }}
          >
            <div style={{textAlign: 'right'}}>
              <div style={{display:'inline-block', cursor: 'pointer'}} 
                onClick={async () => {
                  setIsFetchingPin(true)
                  try {
                    let resp = await PostPublic('gcard/getpin/', {
                      ecard: number,
                      key: state.key,
                    })
                    // console.log('resp', resp);
                    if (resp.status === 1) {
                      setModalPin({
                        show: true,
                        url: url,
                        number: number,
                        pin: resp.data,
                      })
                    } else {
                      setModalPin({
                        show: true,
                        url: url,
                        number: number,
                        pin: '',
                      })
                    }
                  } catch {}
                  setIsFetchingPin(false)
                }}
              >
              {isFetchingPin ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ width: '75px', height: '75px' }}
                >
                  <FaSpinner className="icon-spin" />
                </div>
              ) : (
                <div style={{ width: '75px', height: '75px', background: 'red', display: 'inline-block' }}>
                  <QRCode value={url} size={75} />
                </div>
              )}
              </div>
              <p className='mt-2 text-right' style={{fontSize: '16px'}}>Scan the QR Code to access your <br/>Giftano Card from your
                mobile.</p>
            </div>
          </div>
        )}

          
      </Container>
    </>
  )
}

export default Hero
