import React, {useState,useEffect} from 'react'
import Lottie from 'react-lottie-player';

const GiftBoxAnimation = ({onComplete}) => {
    const [animationData, setAnimationData] = useState();
    useEffect(() => {
      import('../../../assets/lf20_p078vzr8.json').then(setAnimationData);
    }, []);
    return (
      <div style={{background: '#E6F4F2', width:'100%', height: '100vh', position: 'absolute', zIndex: 100}}>
        <div style={{width: '75%', height: '75vh', margin: '0 auto', paddingTop: '10vh'}}>
          <h1 className="text-center" style={{fontSize:'40px', fontWeight: 900}}>Here's a little present for you!</h1>
          <Lottie speed={1.5} animationData={animationData} loop={false} play style={{ width: '100%', height: '100%' }} 
            onComplete={()=>{
              onComplete()
            }}
          />  
        </div>
      </div>
    )
  }

  export default GiftBoxAnimation;