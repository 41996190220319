/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react'
import {
  Container,
  Col,
  Row,
  Button,
  Image,
  ListGroup,
  FormControl,
  InputGroup,
  FormCheck,
  FormGroup,
} from 'react-bootstrap'
import MyNavbar from '../../components/MyNavbar'
import { Link, useParams, useHistory, Redirect } from 'react-router-dom'
import { PostPublic } from '../../services/Connection'
import { AppContext } from '../../states'
import Moment from 'react-moment'
import SwipeButton from 'react-swipezor'
import { toast } from 'react-toastify'
// import { AiOutlineGift } from 'react-icons/ai'
import { FiSearch } from 'react-icons/fi'
import { HiArrowLeft, HiArrowRight } from 'react-icons/hi'
import MyFooter from '../../components/MyFooter'
import { Helmet } from 'react-helmet'
import { AppConfig } from '../../config'
import moment from 'moment'
import ModalVoucherDesc from '../ModalVoucherDesc'
import ModalAddToAccount from '../ModalAddToAccount'
import LoadECard from '../../components/LoadECard'
import { isMembershipActive, strapiImageUrl } from '../../helper'
import ModalConfirm from './components/ModalConfirm'
import ModalShowCode from './components/ModalShowCode'
import ModalRedemptionForm from './components/ModalRedemptionForm'
import ModalRedemptionSuccess from './components/ModalRedemptionSuccess'
import ModalDetailCode from './components/ModalDetailCode'
import EmptyGift from '../../assets/images/empty-gift.png'

const MyGifts = () => {
  const { state, dispatch } = useContext(AppContext)

  const { dataConfirm } = state.myGiftPage

  const [itemsVoucher, setItemsVoucher] = useState([])
  const [filterText, setFilterText] = useState('')

  const [needToShowModalAddAccount, setNeedToShowModalAddAccount] =
    useState(false)
  const [modalAddToAccount, setModalAddToAccount] = useState({ show: false })
  const modalAddToAccountToggle = () => setModalAddToAccount({ show: false })

  const [hideExpired, setHideExpired] = useState(true)

  const { order_id } = useParams()

  const history = useHistory()

  const loadGifts = async () => {
    let resp = await PostPublic('ecard/', {
      ecard: state.ecard.number,
      key: state.key,
      details: 1,
    })
    // console.log('resp ecard more ', resp)
    if (resp.status === 1) {
      dispatch({ type: 'SET_ECARD', data: resp.data })
    } else {
      history.push('/')
    }
  }

  useEffect(() => {
    const existingScript = document.getElementById('giftanoeVoucher')
    if (!existingScript) {
      const script = document.createElement('script')
      script.src = 'https://static-cdn.giftano.com/weco/giftano-evoucher.js'
      script.id = 'giftanoeVoucher'
      document.body.appendChild(script)
    }
  }, [])

  useEffect(() => {
    if (needToShowModalAddAccount) {
      setTimeout(() => {
        setModalAddToAccount({ show: true })
      }, 3000)
    }
  }, [needToShowModalAddAccount])

  useEffect(() => {
    let vouchers = []
    if (state.ecard) {
      // let db = new PouchDB('redemption-gift-result-' + state.ecard.company.id);

      state.ecard.transactions.forEach((transaction) => {
        if (!order_id || (order_id && transaction.id == order_id)) {
          transaction.vouchers.forEach((voucher) => {
            vouchers.push({ ...voucher })
          })
        }
      })

      if (order_id && !state.ecard.memberid && isMembershipActive(state)) {
        setNeedToShowModalAddAccount(true)
      }
    }

    //filter voucher here
    if (filterText !== '') {
      vouchers = vouchers.filter(
        (i) =>
          (i.merchant &&
            i.merchant.name.toLowerCase().indexOf(filterText.toLowerCase()) >
              -1) ||
          (i.code &&
            i.code.toLowerCase().indexOf(filterText.toLowerCase()) > -1) ||
          (i.status &&
            i.status.text.toLowerCase().indexOf(filterText.toLowerCase()) > -1)
      )
    }

    vouchers = vouchers.map((item) => {
      const isExpired = moment(item['expiry-date']).isBefore(moment())
        ? true
        : false
      return { ...item, isExpired }
    })

    // console.log(vouchers, 'voucher e bos');

    setItemsVoucher(vouchers)
  }, [filterText, order_id, state])

  useEffect(() => {
    if (state.gcardinfo && !state.ecard) {
      const loadData = async () => {
        let resp = await PostPublic('ecard/', {
          ecard: state.gcardinfo.number,
          key: state.key,
          details: '1',
        })
        // console.log('resp ecard/', resp)
        if (resp.status === 1) {
          dispatch({ type: 'SET_ECARD', data: resp.data })
        } else {
          toast.warning(resp.message)
          history.push('/')
        }
      }
      loadData()
    }
  }, [dispatch, history, state.ecard, state.gcardinfo, state.key])

  if (!state.whitelabel || !state.gcardinfo) {
    return <Redirect to="/" />
  }

  const resetSlide = (voucher_code) => {
    let newVouchers = [...itemsVoucher]
    newVouchers = newVouchers.map((voucher) => {
      // console.log('voucher', voucher, dataConfirm)
      if (voucher.code === voucher_code) {
        voucher._reset = 1
      }
      return voucher
    })
    // console.log('new Voucher', newVouchers)
    setItemsVoucher(newVouchers)
  }

  const searchChange = async (e) => {
    setFilterText(e.target.value)
  }

  const redeemConfirmNoAction = async () => {
    resetSlide(dataConfirm.code)
    // setShowConfirm(false)
    dispatch({ type: 'MYGIFT_PAGE', payload: { showConfirm: false } })
  }

  const redeemConfirmAction = async (voucher) => {
    // setDataConfirmSend(true)
    dispatch({ type: 'MYGIFT_PAGE', payload: { dataConfirmSend: true } })
    let resp = await PostPublic('get_voucher/code/', {
      merchantid: voucher.merchant.id,
      code: voucher.code,
    })
    // setDataConfirmSend(false)
    dispatch({ type: 'MYGIFT_PAGE', payload: { dataConfirmSend: false } })
    // console.log('resp', resp)
    if (resp.status == 1) {
      resetSlide(voucher.code)
      // setShowConfirm(false)
      // setDataCode(resp.data)
      dispatch({
        type: 'MYGIFT_PAGE',
        payload: {
          showConfirm: false,
          dataCode: resp.data,
        },
      })
      if (resp.data.useowncode == 1) {
        if (
          resp.data.code &&
          (resp.data.code.substr(0, 7) === 'http://' ||
            resp.data.code.substr(0, 8) === 'https://')
        ) {
          // window.open(resp.data.code)
          setTimeout(() => {
            window.open(resp.data.code)
          }, 3000)
          // setShowCode(true)
        } else {
          // setShowCode(true)
        }
        dispatch({
          type: 'MYGIFT_PAGE',
          payload: {
            showCode: true,
          },
        })
      } else {
        if (
          voucher.status.value === 'created' ||
          voucher.status.value === 'valid'
        ) {
          // setShowRedemptionForm(true)
          // setDataRedemptionForm({
          //   customer_name: '',
          //   customer_details: '',
          //   staff_pin: '',
          // })
          // setDataRedemptionFormErrorMessage('')
          // setExpandRedemptionForm(false)
          dispatch({
            type: 'MYGIFT_PAGE',
            payload: {
              showRedemptionForm: true,
              dataRedemptionForm: {
                customer_name: '',
                customer_details: '',
                staff_pin: '',
              },
              dataRedemptionFormErrorMessage: '',
              expandRedemptionForm: false,
            },
          })
        } else {
          // setShowVoucherDetails(true)
          dispatch({
            type: 'MYGIFT_PAGE',
            payload: {
              showVoucherDetails: true,
            },
          })
        }
      }
    } else {
      toast(
        'We are really sorry but we are currently facing some technical issues and we are working hard to resolve them. This issue should be resolved by within the next 24-48h. Sorry for any inconvenience caused.',
        { type: 'warning' }
      )
    }
  }

  const IsShowSlide = (voucher) => {
    // console.log(voucher);
    let retVal = false
    if (voucher['is_physical']) return true
    if (voucher['is-evoucher'] === '0') {
      retVal = false
    } else {
      let status = voucher.status.value
      if (voucher['use-merchant-code'] === '1') {
        if (
          status === 'created' ||
          status === 'valid' ||
          status === 'redeemed'
        ) {
          retVal = true
        } else {
          retVal = false
        }
      } else {
        // use merchant code 0
        if (status === 'created' || status === 'valid') {
          retVal = true
        } else {
          retVal = false
        }
      }
    }
    // console.log('isShow', retVal)
    return retVal
  }

  const getSlideText = (voucher) => {
    if (voucher.is_physical == 1) {
      if (voucher.is_request_sent == 1) {
        return 'View Delivery Request'
      } else {
        return 'Request Delivery'
      }
    } else {
      if (state.ecard.is_gift_box == 1) {
        if (voucher.status.value == 'redeemed') {
          return 'View Code'
        } else {
          return 'Slide to redeem at store'
        }
      } else {
        if (voucher.status.value == 'redeemed') {
          return 'View Code'
        } else {
          return 'Slide to redeem'
        }
      }
    }
  }

  return (
    <div className="page-mygifts">
      <Helmet>
        <title>My Gift(s) - {AppConfig.title}</title>
      </Helmet>
      <div className="rectangle-header">
        <LoadECard>
          {state.ecard && (
            <>
              <div className="mygift__banner banner">
                <Container>
                  <Button
                    as={Link}
                    to="/getstarted"
                    variant="link"
                    className="navbar-back btn-back"
                  >
                    <HiArrowLeft />
                  </Button>
                </Container>
                <h4 className="floating-text">Enjoy your experience!</h4>
                {state.strapiData && state.strapiData.my_gift_banner && (
                  <img
                    className="landing__banner"
                    src={strapiImageUrl(state.strapiData.my_gift_banner.url)}
                    alt="My Gift"
                  />
                )}
              </div>
              <Container className="min-height-alter">
                <MyNavbar />
                {modalAddToAccount.show && (
                  <ModalAddToAccount
                    show={true}
                    toggle={modalAddToAccountToggle}
                    mode={'purchase'}
                  />
                )}
                <Row className="mb-2">
                  <Col md="12" className="title">
                    {state.ecard.is_gift_box == 1 ? (
                      <h5 className="text-black font-weight-bold text-center text-md-left mb-4">
                        Your gift experience
                      </h5>
                    ) : (
                      <h5 className="text-black font-weight-bold text-center text-md-left mb-4">
                        {/* <AiOutlineGift size="33px" className="mr-1" /> */}
                        Redeem your gifts
                      </h5>
                    )}
                    {state.ecard.is_gift_box == 0 && (
                      <Row>
                        <Col md="7" xs="7">
                          <InputGroup className="nav-input mb-3">
                            <FormControl
                              placeholder="Find Gift"
                              value={filterText}
                              onChange={searchChange}
                            />
                            <InputGroup.Append>
                              <Button variant="success">
                                <FiSearch color={'gray'} />
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </Col>
                        <Col
                          xs="5"
                          md="3"
                          lg="2"
                          className="offset-md-1 offset-lg-3"
                        >
                          <FormCheck.Label className="toggle-label">
                            Hide Expired
                          </FormCheck.Label>
                          <FormGroup
                            className="mb-3 button r"
                            controlId="formBasicCheckbox"
                            id="toggle-expired"
                          >
                            <FormCheck>
                              <FormCheck.Input
                                value={hideExpired}
                                onChange={() => setHideExpired(!hideExpired)}
                                type="checkbox"
                                className="checkbox"
                              />
                              <div className="knobs"></div>
                              <div className="layer"></div>
                            </FormCheck>
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <Col md="12" className=" d-flex flex-column-reverse">
                    {itemsVoucher.length === 0 && (
                      <div className="empty-gift">
                        <img
                          className="empty-image"
                          src={EmptyGift}
                          alt="Gift Box"
                        />
                        <p>Looks like you haven’t added any gifts yet.</p>
                      </div>
                    )}
                    {itemsVoucher
                      .filter((voucher) => {
                        if (state.ecard.is_gift_box == 1) return true // show all for giftbox
                        if (
                          hideExpired === true &&
                          voucher.isExpired === true
                        ) {
                          return false
                        }
                        return true
                      })
                      .map((voucher) => (
                        <ListGroup
                          key={voucher.code}
                          className={`voucher-list my-3 ${
                            IsShowSlide(voucher) == false
                              ? 'voucher-list-disabled-container'
                              : ''
                          }`}
                        >
                          <ListGroup.Item
                            className={`${
                              IsShowSlide(voucher) == false
                                ? 'voucher-list-disabled-color'
                                : ''
                            }`}
                          >
                            <Row>
                              <Col className="voucher-image" md="2" xs="4">
                                <Image
                                  src={voucher.file || voucher.merchant.logo}
                                  rounded
                                  fluid
                                  className="merchant-images"
                                />
                              </Col>
                              <Col md="5" xs="8" className="text-left">
                                {voucher['is_physical'] == '1' ||
                                voucher['is-evoucher'] == '1' ? (
                                  ''
                                ) : (
                                  <label className="voucher-tag">
                                    Physical Voucher send via Mail
                                  </label>
                                )}

                                <h4 className="voucher-price">
                                  {voucher.value}
                                </h4>
                                <p className="voucher-merchant-name">
                                  {voucher.merchant.name}
                                </p>
                                {voucher.description &&
                                voucher.description.trim() !== '' ? (
                                  <React.Fragment>
                                    <div className="d-flex justify-content-between">
                                      <p className="voucher-description">
                                        {voucher.description
                                          .replace(/(<([^>]+)>)|&nbsp;/gi, '')
                                          .replace(/\n/g, '<br/>')}
                                      </p>
                                      <Button
                                        variant="link"
                                        size="sm"
                                        onClick={() => {
                                          dispatch({
                                            type: 'SET_MODAL_VOUCHER_DESC',
                                            data: {
                                              show: true,
                                              voucher: voucher,
                                            },
                                          })
                                        }}
                                      >
                                        More
                                      </Button>
                                    </div>
                                    <Button
                                      size="sm"
                                      variant="link"
                                      onClick={() => {
                                        dispatch({
                                          type: 'SET_MODAL_VOUCHER_DESC',
                                          data: {
                                            show: true,
                                            voucher: voucher,
                                          },
                                        })
                                      }}
                                    >
                                      Read Gift Terms &amp; Conditions
                                    </Button>
                                  </React.Fragment>
                                ) : (
                                  <Button
                                    size="sm"
                                    variant="link"
                                    onClick={() => {
                                      dispatch({
                                        type: 'SET_MODAL_VOUCHER_DESC',
                                        data: { show: true, voucher: voucher },
                                      })
                                    }}
                                  >
                                    Read Gift Terms &amp; Conditions
                                  </Button>
                                )}
                              </Col>
                              <Col md="5" className="voucher-info">
                                {IsShowSlide(voucher) ? (
                                  <Row
                                    className="justify-content-center"
                                    noGutters
                                  >
                                    <Col md="12">
                                      <SwipeButton
                                        mainText={getSlideText(voucher)}
                                        overlayText="Unlock"
                                        classList="my-class"
                                        caretClassList="swipe-caret"
                                        caret={<HiArrowRight color="#d5e1e0" />}
                                        overlayClassList="my-overlay-class"
                                        onSwipeDone={() => {
                                          delete voucher._reset
                                          // setDataConfirm(voucher)
                                          dispatch({
                                            type: 'MYGIFT_PAGE',
                                            payload: {
                                              dataConfirm: voucher,
                                            },
                                          })
                                          // console.log('voucher data', voucher)
                                          if (voucher.is_physical == 1) {
                                            // redeemConfirmAction(voucher);
                                            resetSlide(voucher.code)
                                            // setShowRedemptionForm(true)
                                            dispatch({
                                              type: 'MYGIFT_PAGE',
                                              payload: {
                                                showRedemptionForm: true,
                                              },
                                            })
                                            setTimeout(() => {
                                              const gcode = Buffer.from(
                                                voucher.code
                                              ).toString('base64')
                                              const mid = Buffer.from(
                                                voucher.merchant.id
                                              ).toString('base64')
                                              const hostname =
                                                window.location.hostname
                                              if (
                                                hostname === 'cards.giftano.com'
                                              ) {
                                                window.open(
                                                  'https://giftano.com/claim_my_gift?gcode=' +
                                                    gcode +
                                                    '&mid=' +
                                                    mid +
                                                    '&skip_animation=1&ref=' +
                                                    window.location.href,
                                                  '_self'
                                                )
                                              } else {
                                                window.open(
                                                  'https://web-sandbox.giftano.io/claim_my_gift?gcode=' +
                                                    gcode +
                                                    '&mid=' +
                                                    mid +
                                                    '&skip_animation=1&ref=' +
                                                    window.location.href,
                                                  '_self'
                                                )
                                              }
                                            }, 2000)
                                          } else {
                                            if (
                                              voucher['use-merchant-code'] ==
                                                0 ||
                                              voucher.status.value ===
                                                'redeemed'
                                            ) {
                                              redeemConfirmAction(voucher)
                                            } else {
                                              // setShowConfirm(true)
                                              dispatch({
                                                type: 'MYGIFT_PAGE',
                                                payload: {
                                                  showConfirm: true,
                                                },
                                              })
                                            }
                                          }
                                        }}
                                        minSwipeWidth={0.6}
                                        delta={40}
                                        reset={voucher._reset}
                                      />
                                      <div className="voucher-expiry">
                                        Valid until:
                                        <Moment
                                          className="ml-1 font-weight-bold"
                                          date={voucher['expiry-date']}
                                          format="DD-MMM-YYYY"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                ) : (
                                  <label className="voucher-status ">
                                    {voucher.status.text}
                                  </label>
                                )}
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        </ListGroup>
                      ))}
                  </Col>
                  {order_id && state.ecard && (
                    <Col
                      md="10"
                      className="offset-md-1 d-flex flex-column-reverse"
                    >
                      <div className="mt-4 text-center">
                        <Button
                          variant="giftano"
                          onClick={() => history.push('/mygifts')}
                        >
                          View All Gifts
                        </Button>
                      </div>
                    </Col>
                  )}
                </Row>

                <ModalConfirm
                  redeemConfirmAction={redeemConfirmAction}
                  redeemConfirmNoAction={redeemConfirmNoAction}
                />

                <ModalShowCode
                  onHide={() => {
                    resetSlide(dataConfirm.code)
                    loadGifts()
                  }}
                />

                <ModalRedemptionForm
                  onHide={() => {
                    resetSlide(dataConfirm.code)
                    loadGifts()
                  }}
                />

                <ModalRedemptionSuccess
                  onHide={() => {
                    resetSlide(dataConfirm.code)
                    loadGifts()
                  }}
                />

                <ModalDetailCode
                  onHide={() => {
                    resetSlide(dataConfirm.code)
                    loadGifts()
                  }}
                />

                <ModalVoucherDesc />
              </Container>
            </>
          )}
        </LoadECard>
        <MyFooter />
      </div>
    </div>
  )
}

export default MyGifts
