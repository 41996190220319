import { PostPublic } from "./Connection"

export const loadGifts = async (corporateId, sample = 0, totalSample = 6, voucherId = null) => {
    let postValues = {
        'no-discount': 1,
        version: 3,
        corporateid: corporateId,
        combine_cash_voucher: 1,
    }
    if(sample){
        postValues = {
            ...postValues,
            sample: sample,
            total_sample: totalSample
        };
    }
    if(voucherId){
        postValues.vouchertypeid = voucherId;
    }
    let resp = await PostPublic('gift-catalogue/', postValues);
    return resp;
}

export const loadGiftById = async (corporateId, voucherId) => {
    let postValues = {
        'no-discount': 1,
        version: 3,
        corporateid: corporateId,
        combine_cash_voucher: 1,
        vouchertypeid: voucherId
    }
    let resp = await PostPublic('gift-catalogue/', postValues);
    return resp;
}

export const GetShippingCost = async (gift, companyid='') => {
    const res = await PostPublic('shipping-cost/', {
      companyid: companyid,
      vouchertype: gift.id,
      quantity: gift.quantity,
      dymethod: gift.primary_delivery,
    })
    // console.log('result shipping cost', res);
    if (res.status === 1) {
      return res.data
    }
    return 0
  }