/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  ButtonGroup,
  Card,
  // Carousel,
  Col,
  FormControl,
  ListGroup,
  Row,
} from 'react-bootstrap'
import { FaMinus, FaPlus, FaSpinner } from 'react-icons/fa'
import Clamp from 'react-multiline-clamp'
// import LabelGiftTypeChip from '../../../components/LabelGiftTypeChip'
import { AppContext } from '../../../states'
import ModalMerchantInfo from './ModalMerchantInfo'
import { Carousel } from 'react-responsive-carousel'
import Loading from '../../Loading'
import { GetShippingCost } from '../../../services/LegacyService'

const Gifts = () => {
  const { state, dispatch } = useContext(AppContext)
  const { gifts, isRedeem, activeGift } = state.merchantPage
  const [selectedIdx, setSelectedIdx] = useState()
  const [showMerchantInfo, setShowMerchantInfo] = useState(false)
  const toggleMerchantInfo = () => setShowMerchantInfo(false)
  const [merchantInfoData, setMerchantInfoData] = useState({})
  const [timeoutMs, setTimeoutMs] = useState(700)

  useEffect(() => {
    if (activeGift) {
      setTimeout(() => {
        const activeSlide = document.querySelector(
          '#carousel_item_gift_' + activeGift
        )
        if (activeSlide) {
          const height = activeSlide.clientHeight
          activeSlide.closest('.slider-wrapper').style.height = height + 'px'
          setTimeoutMs(200)
        }
      }, timeoutMs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeGift])

  if (state.ecard.is_gift_box == 1 && activeGift === -1) return ''

  // console.log('gifts', gifts)

  // eslint-disable-next-line eqeqeq
  const hidePrice = state.ecard && state.ecard.hide_price == 1 ? true : false
  return (
    <>
      {showMerchantInfo && (
        <ModalMerchantInfo
          show={showMerchantInfo}
          toggle={toggleMerchantInfo}
          merchant={merchantInfoData}
        />
      )}
      {state.ecard.is_gift_box == 1 ? (
        state.ecard.is_gift_box ? (
          <Carousel
            autoPlay={false}
            selectedItem={activeGift}
            showArrows={false}
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
            swipeable={false}
            // dynamicHeight={true}
          >
            {gifts.map((gift, idx) => {
              return (
                <div
                  key={'carousel_item_gift_' + idx}
                  className={'gift-carousel-item'}
                  id={'carousel_item_gift_' + idx}
                >
                  <Row>
                    <Col md={5}>
                      <img
                        className="img-fluid gift-image"
                        src={gift.file}
                        alt={gift.name}
                      />
                    </Col>
                    <Col md={7} className="carousel__content">
                      <Button
                        className="gift-merchant-name merchant-link carousel__subtitle"
                        variant="outline"
                        onClick={() => {
                          setMerchantInfoData(gift.merchant)
                          setShowMerchantInfo(true)
                        }}
                      >
                        {gift.merchant.name}
                      </Button>
                      <h4 className="gift-name carousel__title">{gift.name}</h4>
                      <hr className="mb-4" />
                      <p
                        className="carousel__description"
                        dangerouslySetInnerHTML={{ __html: gift.description }}
                      ></p>

                      <Button
                        block
                        className="btn-select-gift"
                        onClick={async () => {
                          setSelectedIdx(idx)
                          // let newGifts = [...gifts]
                          // newGifts[idx].quantity = 1
                          // newGifts[idx].primary_extrafirst =
                          // await GetShippingCost(newGifts[idx])
                          dispatch({
                            type: 'MERCHANT_PAGE',
                            payload: {
                              // gifts: newGifts,
                              activeGift: idx,
                              showConfirm: true,
                            },
                          })
                        }}
                        disabled={isRedeem}
                      >
                        {isRedeem && selectedIdx === idx && (
                          <FaSpinner className="mr-2 icon-spin" />
                        )}
                        Select
                      </Button>
                    </Col>
                  </Row>
                </div>
              )
            })}
          </Carousel>
        ) : (
          <Loading />
        )
      ) : (
        <Card className="voucher-card">
          <Card.Title className="desc font-weight-bold">
            {hidePrice ? 'What does it include' : 'Gift(s)'}
          </Card.Title>
          <ListGroup className="voucher-list">
            {gifts.map((gift, idx) => {
              if (
                hidePrice &&
                state.ecard.total_usage > 0 &&
                parseFloat(gift.price) > parseFloat(state.ecard.balance)
              ) {
                return ''
              }
              const deliveryFee = gift.primary_extrafirst || 0
              return (
                <ListGroup.Item key={'gift_item_' + idx} className="my-3">
                  <Row noGutters>
                    <Col lg="3" md="4" xs="4">
                      <img
                        className="img-fluid"
                        src={gift.file}
                        alt={gift.name}
                      />
                    </Col>
                    <Col
                      md={hidePrice && state.ecard.total_usage == 0 ? 6 : 8}
                      lg={
                        hidePrice && state.ecard.total_usage == 0
                          ? 6
                          : !hidePrice
                          ? 6
                          : 8
                      }
                      xs="8"
                      className="px-2"
                    >
                      <h4 className="voucher-name">{gift.name}</h4>
                      <Clamp withTooltip lines={2}>
                        <p
                          className="voucher-desc"
                          dangerouslySetInnerHTML={{
                            __html:
                              gift.description &&
                              gift.description
                                .replace(/(<([^>]+)>)|&nbsp;/gi, '')
                                .replace(/\n/g, '<br/>'),
                          }}
                        ></p>
                      </Clamp>
                      <div>
                        <Button
                          size="sm"
                          variant="link"
                          onClick={() => {
                            dispatch({
                              type: 'SET_MODAL_VOUCHER_DESC',
                              data: { show: true, voucher: gift },
                            })
                          }}
                        >
                          Read More &gt;&gt;
                        </Button>
                      </div>
                      {/* <div>
                    <LabelGiftTypeChip tags={gift['tags_v2']} />
                  </div> */}
                    </Col>
                    {hidePrice && state.ecard.total_usage == 0 ? (
                      <Col
                        lg={{ span: '3', offset: '0' }}
                        md={{ span: '6', offset: '3' }}
                        xs={{ span: '8', offset: '4' }}
                        className="voucher-actions"
                      >
                        <Button
                          onClick={async () => {
                            setSelectedIdx(idx)
                            // let newGifts = [...gifts]
                            // newGifts[idx].quantity = 1
                            // newGifts[idx].primary_extrafirst =
                            //   await GetShippingCost(newGifts[idx], state.ecard.company.id)
                            dispatch({
                              type: 'MERCHANT_PAGE',
                              payload: {
                                // gifts: newGifts,
                                activeGift: idx,
                                showConfirm: true,
                              },
                            })
                          }}
                          disabled={isRedeem}
                          className={'btn-list'}
                        >
                          {isRedeem && selectedIdx === idx && (
                            <FaSpinner className="mr-2 icon-spin" />
                          )}
                          SELECT
                        </Button>
                      </Col>
                    ) : !hidePrice ? (
                      <>
                        <Col
                          xs={{ span: '8', offset: '4' }}
                          lg={{ span: '3', offset: '0' }}
                          md={{ span: '6', offset: '3' }}
                          className=" voucher-actions"
                        >
                          <h4 className="voucher-price text-center">
                            {gift['currency-symbol']}
                            {gift.price}
                          </h4>
                          {deliveryFee > 0 && (
                            <p>
                              +${deliveryFee.toLocaleString('en')} Delivery Fee
                            </p>
                          )}

                          <ButtonGroup className=" voucher-qty">
                            <Button
                              variant="light"
                              onClick={async () => {
                                let newGifts = [...gifts]
                                newGifts[idx].quantity--
                                if (newGifts[idx].quantity < 0) {
                                  newGifts[idx].quantity = 0
                                }
                                if (newGifts[idx].quantity > 0) {
                                  newGifts[idx].primary_extrafirst =
                                    await GetShippingCost(
                                      newGifts[idx],
                                      state.ecard.company.id
                                    )
                                } else {
                                  newGifts[idx].primary_extrafirst = 0
                                }
                                // setGifts(newGifts)
                                dispatch({
                                  type: 'MERCHANT_PAGE',
                                  payload: {
                                    gifts: newGifts,
                                  },
                                })
                              }}
                            >
                              <FaMinus />
                            </Button>
                            <FormControl
                              as="button"
                              className="text-center"
                              value={gift.quantity}
                              onChange={(e) => {
                                let newGifts = [...gifts]
                                newGifts[idx].quantity = parseInt(
                                  e.target.value
                                )
                                // setGifts(newGifts)
                                dispatch({
                                  type: 'MERCHANT_PAGE',
                                  payload: {
                                    gifts: newGifts,
                                  },
                                })
                              }}
                            >
                              {gift.quantity}
                            </FormControl>
                            <Button
                              variant="light"
                              onClick={async () => {
                                let newGifts = [...gifts]
                                newGifts[idx].quantity++
                                if (newGifts[idx].quantity > 10)
                                  newGifts[idx].quantity = 10
                                if (newGifts[idx].quantity > 0) {
                                  newGifts[idx].primary_extrafirst =
                                    await GetShippingCost(
                                      newGifts[idx],
                                      state.ecard.company.id
                                    )
                                } else {
                                  newGifts[idx].primary_extrafirst = 0
                                }
                                // setGifts(newGifts)
                                dispatch({
                                  type: 'MERCHANT_PAGE',
                                  payload: {
                                    gifts: newGifts,
                                  },
                                })
                              }}
                            >
                              <FaPlus />
                            </Button>
                          </ButtonGroup>
                        </Col>
                      </>
                    ) : (
                      ''
                    )}
                  </Row>
                </ListGroup.Item>
              )
            })}
          </ListGroup>
        </Card>
      )}
    </>
  )
}

export default Gifts
