import React from 'react'
import { Button, Carousel, Col, Modal, Row } from 'react-bootstrap'
import { BiMap } from 'react-icons/bi'
import Clamp from 'react-multiline-clamp'

const ModalMerchantInfo = ({ show, toggle, merchant }) => {
  return (
    <Modal
      show={show}
      onHide={toggle}
      size="xl"
      className="modal-merchant-info"
    >
      <Modal.Header closeButton></Modal.Header>
      <div className="p-3">
        <Row>
          <Col xs="12" md="6">
            <Carousel className="modal-merchant-carousel">
              {merchant.images.map((image, imgIdx) => (
                <Carousel.Item key={'merchant_image_item_' + imgIdx}>
                  <img className="img-fluid" src={image} alt={merchant.name} />
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
          <Col xs="12" md="6">
            <div className="d-flex justify-content-between">
              <h4 className="title">{merchant.name}</h4>
            </div>
            <hr />
            <Clamp withTooltips withToggle lines={5}>
              <p className="description">{merchant.desc}</p>
            </Clamp>

            <div className="contacts subtitle">
              {merchant.contacts && merchant.contacts.length > 0 && (
                <>
                  <h4 className="subtitle ">Contact</h4>
                  <hr />
                  {merchant.contacts.map((contact, idx) => (
                    <div key={'contact_item_' + idx}>
                      <p>
                        <strong>{contact.name}</strong>
                      </p>
                      {contact.phone && <p>Phone : {contact.phone}</p>}
                      {contact.email && <p>Email : {contact.email}</p>}
                    </div>
                  ))}
                </>
              )}
            </div>
            {merchant.address && (
              <>
                <h4 className="title font-weight-bold">Address</h4>
                <p
                  className="font-weight-bold"
                  style={{ marginTop: '35px', marginBottom: '35px' }}
                >
                  <BiMap className="mr-2" /> {merchant.address.address} <br />{' '}
                  {merchant.address.postalcode}
                </p>
              </>
            )}

            <Button
              variant="outline-primary"
              onClick={toggle}
              className={'mb-4'}
            >
              Done
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default ModalMerchantInfo
