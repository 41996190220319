import React, { useEffect, useState } from 'react'
import { Image, Spinner } from 'react-bootstrap'
import { FaExclamationTriangle } from 'react-icons/fa'
// import placeholderImg from '../assets/images/placeholder-merchant.png';

const MyImg = (props) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isError, setIsError] = useState(false)
  const [startLoading, setStartLoading] = useState(false);
  const onImgLoaded = () => {
    setIsLoaded(true)
  }
  useEffect(()=>{
    setIsLoaded(false);
    setIsError(false);
    if(props.src){
      setStartLoading(true);
    }
  },[props.src])

  return (
    <div>
      {/* <Image {...props} src={placeholderImg} style={{display: isLoaded ? 'none' : 'block', width: '100%'}} /> */}
      <div
        style={{
          display: isLoaded ? 'none' : 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: props.bgcolor || 'transparent',
          width: '100%',
          height: props.height || '200px',
        }}
      >
        <Spinner
          animation="border"
          variant={props.spinnervariant || 'primary'}
        />
      </div>
      <div
        style={{
          display: isError ? 'flex' : 'none',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: props.bgcolor || 'transparent',
          width: '100%',
          height: '100px',
        }}
      >
        <div className="text-center text-light">
          <FaExclamationTriangle />
          <p style={{ fontSize: '80%' }}>ERROR LOAD IMAGE</p>
        </div>
      </div>
      {startLoading && (
        <Image
          {...props}
          onLoad={onImgLoaded}
          onError={(err) => {
            setIsLoaded(true)
            setIsError(true)
          }}
          style={{ display: isLoaded && !isError ? 'block' : 'none' }}
        />
      )}
    </div>
  )
}

export default MyImg
