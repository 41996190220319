import moment from 'moment';
import React, { useContext } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { AppContext } from '../../../states';

const ModalRedemptionSuccess = ({onHide}) => {
    const {state, dispatch} = useContext(AppContext);
    const {
        showRedemptionSuccess,
        dataConfirm,
        dataCode,
        dataRedemptionSuccess
    } = state.myGiftPage;
    return (
        <Modal
            centered
            className="modal-redemptionsuccess"
            show={showRedemptionSuccess}
            onHide={() => {
                // setShowRedemptionSuccess(false)
                dispatch({
                    type: 'MYGIFT_PAGE', payload: {
                        showRedemptionSuccess: false
                    }
                });
                onHide();
            } }
        >
                <Modal.Header closeButton>
                    <Modal.Title>Redemption Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="redemption-success">
                        {/* {dataConfirm.merchant && dataConfirm.merchant.logo && (
    <img width="100" src={dataConfirm.merchant.logo} />
    )} */}
                        {// eslint-disable-next-line eqeqeq
                            (dataConfirm.is_physical == '1' && dataConfirm.file) ? (
                                <img width="200" src={dataConfirm.file} alt="Product" />
                            ) : (dataConfirm.merchant && dataConfirm.merchant['e-voucher-bg']) ? (
                                <giftano-evoucher
                                    logo={dataConfirm.merchant ? dataConfirm.merchant.logo : ''}
                                    name={dataConfirm.value}
                                    expDate={dataConfirm['expiry-date'] ? moment(dataConfirm['expiry-date']).format('DD-MMM-YYYY') : ''}
                                    bgImage={dataConfirm.merchant['e-voucher-bg']}
                                    style={{ boxShadow: '2px 2px 5px', display: 'Block', marginBottom: '16px' }} />
                            ) : (dataConfirm.merchant && dataConfirm.merchant.logo) ? (
                                <img width="100" src={dataConfirm.merchant.logo} alt="Logo" />
                            ) : null}
                        <h4>{dataConfirm.value}</h4>
                    </div>
                    <div className="redemption-success-detail">
                        <h4>Successfully Redeemed</h4>
                        <Form.Group as={Row}>
                            <Form.Label column md="6">
                                Voucher Code :
                            </Form.Label>
                            <Col md="6">
                                <Form.Control
                                    disabled
                                    name="redemption-success-code"
                                    value={dataCode.code} />
                            </Col>
                            <Form.Label column md="6">
                                Redemption number :
                            </Form.Label>
                            <Col md="6">
                                <Form.Control
                                    disabled
                                    name="redemption-success-number"
                                    value={dataRedemptionSuccess.referenceNumber} />
                            </Col>
                        </Form.Group>
                    </div>
                </Modal.Body>
            </Modal>
    )
}

export default ModalRedemptionSuccess;