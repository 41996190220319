import React, { useContext } from 'react'
import { Button, Carousel, Col, Row } from 'react-bootstrap'
import Clamp from 'react-multiline-clamp'
import MyImg from '../../../components/MyImg'
import { AppContext } from '../../../states'

const MerchantInfo = () => {
  const { state, dispatch } = useContext(AppContext)
  const { activeMerchant, gifts } = state.merchantPage
  let howItWork = ''
  const arr = [
    ...new Set(gifts.map((i) => (i.is_physical ? 'physical' : 'default'))),
  ]
  if (arr.length === 1) {
    state.strapiData.how_it_work
      .filter((how) => how.slug === arr[0])
      .forEach((how) => {
        howItWork = how.text
      })
  }

  // console.log('merchant info', activeMerchant)
  return (
    <Row className="merchant-product__info">
      <Col md="12" xs="12" lg="6">
        {activeMerchant.images && (
          <Carousel
            onLoadedData={(e) => console.log('loaded data', e)}
            onLoadedDataCapture={(e) => console.log('on loaded capture', e)}
          >
            {activeMerchant.images.map((imgSrc, imgIdx) => (
              <Carousel.Item key={'carrousel_item_' + imgIdx}>
                <MyImg src={imgSrc} rounded fluid className="merchant-images" />
              </Carousel.Item>
            ))}
          </Carousel>
        )}
      </Col>
      <Col md="12" xs="12" lg="6" className="pl-3 text-left">
        <h4
          className={
            'merchant-name font-weight-bold text-uppercase ' +
            (activeMerchant.name && activeMerchant.name.length > 60
              ? 'merchant-name-long'
              : '')
          }
        >
          {activeMerchant.name}
        </h4>
        <Clamp
          withTooltip
          withToggle
          lines={2}
          maxLines={100}
          showMoreElement={({ toggle }) => (
            <Button size="sm" variant="link" className="links" onClick={toggle}>
              Read more &gt;&gt;
            </Button>
          )}
          showLessElement={({ toggle }) => (
            <Button size="sm" variant="link" onClick={toggle}>
              &lt;&lt; Show less
            </Button>
          )}
        >
          <p
            className="merchant-desc"
            dangerouslySetInnerHTML={{
              __html:
                activeMerchant.desc &&
                activeMerchant.desc
                  .replace(/(<([^>]+)>)|&nbsp;/gi, '')
                  .replace(/\n/g, '<br/>'),
            }}
          ></p>
        </Clamp>
        {howItWork && (
          <>
            <h4 className="desc font-weight-bold">How it works</h4>
            <p>{howItWork}</p>
          </>
        )}
        {activeMerchant.tnc && (
          <Button
            size="sm"
            variant="link"
            className="links"
            onClick={() => {
              dispatch({
                type: 'MERCHANT_PAGE',
                payload: {
                  showModalTnc: true,
                },
              })
            }}
          >
            Read Gift Terms &amp; Conditions
          </Button>
        )}
      </Col>
    </Row>
  )
}

export default MerchantInfo
