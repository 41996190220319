import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Connection, PostPublic } from '../services/Connection';
import { AppContext } from '../states';
import { ErrorMessages } from './ErrorMessages';
import { SHA1 } from 'crypto-js'
import { FaSpinner } from 'react-icons/fa';

const params = new URLSearchParams(window.location.search);

const LoadECard = ({children}) => {
    const { state, dispatch } = useContext(AppContext)
    const history = useHistory();

    useEffect(() => {
        if (state.gcardinfo && !state.ecard && state.strapiData.membership) {
          const loadData = async () => {
            let resp = await PostPublic('ecard/', {
              ecard: state.gcardinfo.number,
              key: state.key,
              details: '1',
            })
            // console.log('resp ecard/', resp)
            if (resp.status === 1) {
              let loginRequired = false;
              let membershipFeature = false;
              const cardNeedMemberAccount = resp.data.need_member_account;
              const strapiNeedMemberAccount = state.strapiData.member_account_required;
              const cardMembership = resp.data.membership;
              const strapiMembership = state.strapiData.membership;
              // eslint-disable-next-line eqeqeq
              if(cardNeedMemberAccount == 9){
                // use info from strapi
                loginRequired = strapiNeedMemberAccount;
              }else{
                // eslint-disable-next-line eqeqeq
                loginRequired = cardNeedMemberAccount == 1 ? true : false;
              }
              // check if login required ignore all membership setting and set to true
              if(loginRequired){
                membershipFeature = true;
              }else{
                // check card info first then strapi
                // eslint-disable-next-line eqeqeq
                if(cardMembership == 9){
                  membershipFeature = strapiMembership;
                }else{
                  // eslint-disable-next-line eqeqeq
                  membershipFeature = cardMembership == 1 ? true : false;
                }
              }

              const redirectToLogin = () => {
                const redirect_auth = process.env.REACT_APP_REDIRECT_AUTH;
                const locationWindow = window.location.protocol + '//' + window.location.host + window.location.pathname + "?";
                const redirect_url = redirect_auth + "?redirect=" + btoa(locationWindow + "|member") + "&rb=" + btoa(window.location.origin + '/getstarted') + '&mode3';
                // alert('redirect to ' + redirect_url)
                window.location = redirect_url;
              }

              if(loginRequired && !state.session_id){
                toast.warning(ErrorMessages.error_need_login, {
                  onClose: redirectToLogin
                });
                return;
              }

              if(resp.data.memberid && membershipFeature === true){
                if(!(state.member && state.member.id === resp.data.memberid)){
                  console.log('member not match',state, resp);
                  toast.error(ErrorMessages.error_member_not_match,{
                    position: 'top-center',
                    onClose: async ()=>{
                      await Connection({url: 'auth/logout', method: 'POST', headers: {session_id: state.session_id}});
                      redirectToLogin();
                    }
                  });
                  return;
                }
                
              }else{
                // not belong to member
                // check url param is savecard or not
                let savecard = false;
                if(params.has('savecard')){
                  savecard = params.get('savecard') === 'true' ? true : false;
                }
                if(savecard){
                  const number = resp.data.number
                  const session_id = state.session_id
                  const ipaddress = state.ipaddress
                  let res = await PostPublic('gcard/set-member/', {
                    ecard: SHA1(number).toString(),
                    session_id: session_id,
                    ipaddress: ipaddress,
                  })
                  if (res.status === 1) {
                    toast.success('Success add giftano card into your account')
                    resp.data.memberid = state.member.id;
                    window.history.pushState(null, 'Get Started', window.location.protocol + '//' + window.location.host + window.location.pathname);
                  } else {
                    toast.warning(res.message || ErrorMessages.error_set_member)
                  }

                }
              }

              dispatch({ type: 'SET_ECARD', data: resp.data })
            } else {
              toast.warning(resp.message)
              history.push('/');
            }
          }
          loadData()
        }
      }, [dispatch, history, state, state.ecard, state.gcardinfo, state.ipaddress, state.key, state.member, state.session_id, state.strapiData.member_account_required, state.strapiData.membership])
      
    return (
      <React.Fragment>
        {state.ecard ? children : (
          <div className='d-flex justify-content-center align-items-center' style={{height: '100vh', width: '100%', color: 'gray', fontSize: '28px'}}>
            <div className='text-center'>
              <FaSpinner className="icon-spin mr-2"/> Loading your card...
            </div>
          </div>
        )}
      </React.Fragment>
    )
}

export default LoadECard;