/* eslint-disable eqeqeq */
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { PostMerchant } from '../../../services/Connection';
import { AppContext } from '../../../states';

const ModalRedemptionForm = ({onHide}) => {
    const [dataRedemptionFormSend, setDataRedemptionFormSend] = useState(false)
    const { state, dispatch } = useContext(AppContext);
    const {
        dataConfirm,
        dataCode,
        showRedemptionForm,
        dataRedemptionForm,
        dataRedemptionFormErrorMessage,
        expandRedemptionForm,
    } = state.myGiftPage;

    let redirectTimer;

    const redeemAction = async () => {
        // console.log('redeem action ', dataRedemptionForm)
    
        if (!dataRedemptionForm.staff_pin) {
          toast('Enter Staff PIN !!', { type: 'warning' })
          return
        }
    
        setDataRedemptionFormSend(true)
        let resRedeem = await PostMerchant('redeemvoucher/', {
          code: dataConfirm.code,
          redemptionkey: dataRedemptionForm.staff_pin,
          name: dataRedemptionForm.customer_name,
          icnumber: dataRedemptionForm.customer_details,
        })
        setDataRedemptionFormSend(false)
        // console.log('redemption response : ', resRedeem)
    
        // eslint-disable-next-line eqeqeq
        if (resRedeem.status == 1) {
        //   setShowRedemptionForm(false)
        //   setDataRedemptionSuccess({ referenceNumber: resRedeem.referenceNumber })
        //   setShowRedemptionSuccess(true)
        //   setDataRedemptionForm({
        //     customer_name: '',
        //     customer_details: '',
        //     staff_pin: '',
        //   })
        //   setDataRedemptionFormErrorMessage('')
            dispatch({type: 'MYGIFT_PAGE', payload: {
                showRedemptionForm: false,
                dataRedemptionSuccess: {referenceNumber: resRedeem.referenceNumber},
                showRedemptionSuccess: true,
                dataRedemptionForm: {
                    customer_name: '',
                    customer_details: '',
                    staff_pin: ''
                },
                redemptionFormErrorMessage: ''
            }});
        } else {
          if (
            resRedeem.message === 'Customer Name and Customer Details is required.'
          ) {
            // setExpandRedemptionForm(true)
            dispatch({type: 'MYGIFT_PAGE', payload: {
              expandRedemptionForm: true  
            }})
          }
        //   setDataRedemptionFormErrorMessage(resRedeem.message)
          dispatch({type: 'MYGIFT_PAGE', payload: {
              dataRedemptionFormErrorMessage: resRedeem.message
          }});
        }
      }


    return (
        <Modal
            centered
            className="modal-redemption"
            show={showRedemptionForm}
            onHide={()=>{
                dispatch({type: 'MYGIFT_PAGE', payload: {
                    showRedemptionForm: false
                }});
                onHide();
            }}
            backdrop="static"
        >
            <Modal.Header closeButton>
            <Modal.Title>REDEEM GIFT</Modal.Title>
            <div>Only use this in store and by store's staff</div>
            </Modal.Header>

            <Modal.Body>
            <Row>
                <Col md="12">
                {// eslint-disable-next-line eqeqeq
                    (dataConfirm.is_physical == '1' && dataConfirm.file) ? (
                    <img width="200" src={dataConfirm.file} alt="Product" />
                    ) : (dataConfirm.merchant && dataConfirm.merchant['e-voucher-bg']) ? (
                    <giftano-evoucher
                        logo={dataConfirm.merchant ? dataConfirm.merchant.logo : ''}
                        name={dataConfirm.value}
                        expDate={dataConfirm['expiry-date'] ? moment(dataConfirm['expiry-date']).format('DD-MMM-YYYY') : ''}
                        bgImage={dataConfirm.merchant['e-voucher-bg']}
                        style={{ boxShadow: '2px 2px 5px', display: 'Block', marginBottom: '16px' }} />
                    ) : (dataConfirm.merchant && dataConfirm.merchant.logo) ? (
                    <img width="200" src={dataConfirm.merchant.logo} alt="Logo" />
                    ) : null
                }
                </Col>
                <Col md="12">
                <div className="showcode-title">
                    <h4>{dataConfirm.value}</h4>
                </div>
                </Col>
                {
                // eslint-disable-next-line eqeqeq
                dataConfirm.is_physical == 1 ? (
                    <Col md="12">
                    <p style={{ marginTop: '16px' }}>
                        You will be redirected to Delivery Page. If you're not redirected in few seconds, please click button below
                    </p>
                    </Col>
                ) : (
                    <React.Fragment>
                    <Col md="12">
                        <img
                        className="showcode-barcode"
                        src={
                            'https://giftano.com/gtools/barcode/?text=' +
                            dataCode.code +
                            '&type=' +
                            dataCode.barcode_type +
                            '&height=60'
                        }
                        alt="Barcode"
                        />
                    </Col>
                    <Col md="12" className="showcode-detail">
                        <span>{dataCode.code}</span>
                    </Col>
                    <Col md="12">
                        <Form onSubmit={(e)=>{
                            e.preventDefault();
                            redeemAction();
                        }}>
                        <Form.Group className="text-left">
                            <Form.Label>
                            Please ask Merhant/Staff to enter their PIN:
                            </Form.Label>
                            <Form.Control
                            name="staff_pin"
                            value={dataRedemptionForm.staff_pin}
                            placeholder="Enter PIN (Required)"
                            onChange={(e) => {
                                // setDataRedemptionForm({
                                // ...dataRedemptionForm,
                                // staff_pin: e.target.value,
                                // })
                                let newData = {...dataRedemptionForm};
                                newData.staff_pin = e.target.value;
                                dispatch({type: 'MYGIFT_PAGE', payload: {
                                    dataRedemptionForm: newData
                                }});
                            }}
                            />
                        </Form.Group>
                        {expandRedemptionForm && (
                            <React.Fragment>
                            <Form.Group as={Row}>
                                <Form.Label column md="4" xs="5">
                                Customer Name
                                </Form.Label>
                                <Col md="8" xs="7">
                                <Form.Control
                                    name="customer_name"
                                    placeholder="Customer Name"
                                    value={dataRedemptionForm.customer_name}
                                    onChange={(e) => {
                                        // setDataRedemptionForm({
                                        //     ...dataRedemptionForm,
                                        //     customer_name: e.target.value,
                                        // })
                                        let newData = {...dataRedemptionForm};
                                        newData.customer_name = e.target.value;
                                        dispatch({type: 'MYGIFT_PAGE', payload: {
                                            dataRedemptionForm: newData
                                        }});
                                    }}
                                />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column md="4" xs="5">
                                Customer Details
                                </Form.Label>
                                <Col md="8" xs="7">
                                <Form.Control
                                    name="customer_details"
                                    placeholder="Customer Details"
                                    value={dataRedemptionForm.customer_details}
                                    onChange={(e) => {
                                        // setDataRedemptionForm({
                                        //     ...dataRedemptionForm,
                                        //     customer_details: e.target.value,
                                        // })
                                        let newData = {...dataRedemptionForm};
                                        newData.customer_details = e.target.value;
                                        dispatch({type: 'MYGIFT_PAGE', payload: {
                                            dataRedemptionForm: newData
                                        }});
                                    }}
                                />
                                </Col>
                            </Form.Group>
                            </React.Fragment>
                        )}
                        </Form>
                        {dataRedemptionFormErrorMessage && (
                        <Alert variant="danger">
                            {dataRedemptionFormErrorMessage}
                        </Alert>
                        )}
                    </Col>
                    </React.Fragment>
                )
                }
            </Row>
            </Modal.Body>

            <Modal.Footer>
            {
                // eslint-disable-next-line eqeqeq
                dataConfirm.is_physical == 1 ? (
                <Button onClick={() => {
                    // console.log(dataConfirm, dataCode)
                    clearTimeout(redirectTimer);
                    const gcode = Buffer.from(dataConfirm.code).toString('base64');
                    const mid = Buffer.from(dataConfirm.merchant.id).toString('base64');
                    const hostname = window.location.hostname;
                    if (hostname === 'cards.giftano.com') {
                    window.open('https://giftano.com/claim_my_gift?gcode=' + gcode + '&mid=' + mid + '&skip_animation=1').focus();
                    } else {
                    window.open('https://web-sandbox.giftano.io/claim_my_gift?gcode=' + gcode + '&mid=' + mid + '&skip_animation=1').focus();
                    }
                }}>
                    {dataConfirm.is_request_sent == 1 ? 'View My Gift' : 'Get My Gift'}
                </Button>
                ) : (
                <Button onClick={redeemAction}>
                    {dataRedemptionFormSend && (
                    <FaSpinner className="icon-spin mr-2" />
                    )}
                    REDEEM
                </Button>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default ModalRedemptionForm;