import moment from 'moment';
import React, {useContext} from 'react';
import { Button, Modal } from "react-bootstrap";
import { FaSpinner } from 'react-icons/fa';
import { TiWarningOutline } from 'react-icons/ti';
import { AppContext } from '../../../states';

const ModalConfirm = ({redeemConfirmAction, redeemConfirmNoAction}) => {
    const { state } = useContext(AppContext);
    const { showConfirm, dataConfirm, dataConfirmSend } = state.myGiftPage;

    return (
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showConfirm}
        onHide={redeemConfirmNoAction}
        className="mygift-modal"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-giftano-orange">
            <div>
              <TiWarningOutline size="30px" />
            </div>
            <div>REDEEM GIFT</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-confirmation mb-4">
            <h5 className="attention mb-3">
              Are you ready to redeem your gift at the merchant store?
            </h5>
            {dataConfirm['earliest-code-exp-date'] && dataConfirm['earliest-code-exp-data'] < dataConfirm['expiry-date'] && (
              <p style={{fontSize: '90%', marginBottom: '32px'}}>Once you press "Yes", please use your gift before {moment(dataConfirm['earliest-code-exp-date']).format('DD-MMM-YYYY')}.</p>
            )}
            <div className="modal-confirmation__action">
              <Button
                disabled={dataConfirmSend}
                variant="secondary"
                onClick={redeemConfirmNoAction}
                className="modal-footer-cancel"
              >
                No
              </Button>{' '}
              <Button
                disabled={dataConfirmSend}
                variant="primary"
                onClick={() => redeemConfirmAction(dataConfirm)}
                className="modal-footer-submit"
              >
                {dataConfirmSend && (
                  <FaSpinner className="icon-spin mr-2" />
                )}
                Yes
              </Button>
            </div>
          </div>

          <h5 className="attention mb-3">Redemption Guidelines</h5>
          <p
            className="howto"
            dangerouslySetInnerHTML={{
              __html: dataConfirm['redemption-guidelines'],
            }}
          />
        </Modal.Body>
        <Modal.Footer className="mb-4 text-center"></Modal.Footer>
      </Modal>
    )
}

export default ModalConfirm;