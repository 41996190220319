import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
// import GA4React from "ga-4-react";

// const ga4react = new GA4React("G-4MYPYSYSE8");



const mode = process.REACT_APP_BUILD_MODE || 'SANDBOX';
if(window.location.hostname !== 'localhost'){
  Sentry.init({
    dsn: "https://a6c5ad29eba448fab737f1b638af2f2f@o1089454.ingest.sentry.io/6104587",
    integrations: [new BrowserTracing()],
    environment: mode.toLowerCase(),
    initialScope: {
      tags: {
        team: 'front-end',
        'customer.b2b': true,
        'customer.b2c': true,
      }
    },
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
    ignoreErrors:[
      "Non-Error exception captured",
      "Non-Error promise rejection captured"
    ]
  });
}


(async () => {
  // try{
    // await ga4react.initialize();
  // }catch{}
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
})();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
