export const isMembershipActive = (state) => {
    let loginRequired = false;
    let membershipFeature = false;
    const cardNeedMemberAccount = state.ecard.need_member_account;
    const strapiNeedMemberAccount = state.strapiData.member_account_required;
    const cardMembership = state.ecard.membership;
    const strapiMembership = state.strapiData.membership;
    // eslint-disable-next-line eqeqeq
    if(cardNeedMemberAccount == 9){
        // use info from strapi
        loginRequired = strapiNeedMemberAccount;
    }else{
        // eslint-disable-next-line eqeqeq
        loginRequired = cardNeedMemberAccount == 1 ? true : false;
    }
    // check if login required ignore all membership setting and set to true
    if(loginRequired){
        membershipFeature = true;
    }else{
        // check card info first then strapi
        // eslint-disable-next-line eqeqeq
        if(cardMembership == 9){
            membershipFeature = strapiMembership;
        }else{
            // eslint-disable-next-line eqeqeq
            membershipFeature = cardMembership == 1 ? true : false;
        }
    }
    return membershipFeature;
}

export const strapiImageUrl = (url) => {
    if(url){
        if(url.substr(0,4) === 'http'){
            return url;
        }else{
            return 'https://content.giftano.com' + url;
        }
    }
    return url;
}
