import moment from 'moment';
import React, { useContext } from 'react';
import { Modal } from "react-bootstrap";
import { AppContext } from '../../../states';

const ModalDetailCode = ({onHide}) => {
    const {state, dispatch} = useContext(AppContext);
    const {
        showVoucherDetails,
        dataConfirm,
        dataCode,
    } = state.myGiftPage;

    return (
        <Modal
            className="modal-detailcode"
            show={showVoucherDetails}
            onHide={() => {
                // setShowVoucherDetails(false)
                dispatch({ type: 'MYGIFT_PAGE', payload: {
                    showVoucherDetails: false
                }});
                onHide();
            } }
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Voucher Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="voucher-details-info">
                    {// eslint-disable-next-line eqeqeq
                        (dataConfirm.is_physical == '1' && dataConfirm.file) ? (
                            <img width="200" src={dataConfirm.file} alt="Product" />
                        ) : (dataConfirm.merchant && dataConfirm.merchant['e-voucher-bg']) ? (
                            <giftano-evoucher
                                logo={dataConfirm.merchant ? dataConfirm.merchant.logo : ''}
                                name={dataConfirm.value}
                                expDate={dataConfirm['expiry-date'] ? moment(dataConfirm['expiry-date']).format('DD-MMM-YYYY') : ''}
                                bgImage={dataConfirm.merchant['e-voucher-bg']}
                                style={{ boxShadow: '2px 2px 5px', display: 'Block', marginBottom: '16px' }} />
                        ) : (dataConfirm.merchant && dataConfirm.merchant.logo) ? (
                            <img width="100" src={dataConfirm.merchant.logo} alt="Logo" />
                        ) : null}
                    <h4>{dataConfirm.value}</h4>
                </div>
                {dataConfirm.status &&
                    ((dataConfirm.status.value !== 'valid' &&
                        dataConfirm.status.value !== 'created' &&
                        // eslint-disable-next-line eqeqeq
                        dataConfirm['use-merchant-code'] == 0) ||
                        (dataConfirm.status.value !== 'valid' &&
                            dataConfirm.status.value !== 'created' &&
                            dataConfirm.status.value !== 'redeemed' &&
                            // eslint-disable-next-line eqeqeq
                            dataConfirm['use-merchant-code'] == 1)) ? (
                    <label className="redeem-status">
                        {dataConfirm.status ? dataConfirm.status.text : ''}
                    </label>
                ) : (
                    ''
                )}
                {/* <BarCode margin={12} value={dataCode.code} /> */}
                <div className="voucher-details-barcode">
                    <img
                        src={'https://giftano.com/gtools/barcode/?text=' +
                            dataCode.code +
                            '&type=' +
                            dataCode.barcode_type +
                            '&height=60'}
                        alt="Barcode" />
                    <h4>CODE: {dataCode.code}</h4>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalDetailCode;