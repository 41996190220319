import React from 'react'
import ContentLoader from 'react-content-loader'

const CardListSkeleton = (props) => (
  <ContentLoader
    backgroundColor="var(--gf-neutral-200)"
    foregroundColor="var(--gf-light-gray-200)"
    {...props}
  >
    <rect x="0" y="30" rx="2" ry="2" width="400" height="100%"></rect>
    <rect x="500" y="30" rx="4" ry="4" width="600" height="20%"></rect>
    <rect x="500" y="30%" rx="4" ry="4" width="600" height="50%"></rect>
    <rect x="500" y="85%" rx="4" ry="4" width="600" height="15%"></rect>
  </ContentLoader>
)

export default CardListSkeleton
