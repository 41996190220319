/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { AppContext } from '../../states'
import MyNavbar from '../../components/MyNavbar'
import MyFooter from '../../components/MyFooter'
import { Helmet } from 'react-helmet'
import { AppConfig } from '../../config'
import LoadECard from '../../components/LoadECard'
import ModalAddToAccount from '../ModalAddToAccount'
import { isMembershipActive } from '../../helper'
import GetInspired from './components/GetInspired'
import Hero from './components/Hero'
import About from './components/About'
import GiftBoxAnimation from './components/GiftboxAnimation'
import { Animated } from 'react-animated-css'
import { Alert, Col, Container, Row } from 'react-bootstrap'
import { FaCheckCircle } from 'react-icons/fa'

const GetStarted = () => {
  const { state, dispatch } = useContext(AppContext)
  const [modalAddToAccount, setModalAddToAccount] = useState({ show: false })
  const modalAddToAccountToggle = () => setModalAddToAccount({ show: false })

  useEffect(() => {
    if (
      state.ecard &&
      state.strapiData &&
      !state.ecard.memberid &&
      isMembershipActive(state)
    ) {
      setTimeout(() => {
        // check local storeage
        const isInvited = localStorage.getItem('invitedMember')
        if (!isInvited && state.ecard.is_gift_box == 0) {
          setModalAddToAccount({ show: true })
        }
      }, 3000)
    }
  }, [state])

  if (!state.whitelabel || !state.gcardinfo) return <Redirect to="/" />

  const hidePrice = state.ecard && state.ecard.hide_price == 1 ? true : false

  // console.log('state', state);
  return (
    <div
      className={`${
        state.ecard && state.ecard.is_gift_box == 1 ? 'gift-box' : 'gift-card'
      } page-getstarted`}
    >
      <Helmet>
        <title>{AppConfig.title}</title>
      </Helmet>
      <LoadECard>
        {state.ecard &&
          state.ecard.is_gift_box == 1 &&
          state.getStartedPage.giftBoxAnimation && (
            <Animated
              animationIn="fadeIn"
              animationOut="fadeOut"
              isVisible={state.getStartedPage.giftBoxAnimation}
            >
              <GiftBoxAnimation
                onComplete={() => {
                  dispatch({
                    type: 'GETSTARTED_PAGE',
                    payload: { giftBoxAnimation: false },
                  })
                }}
              />
            </Animated>
          )}
        {state.ecard &&
          (state.ecard.is_gift_box == 0 ||
            !state.getStartedPage.giftBoxAnimation) && (
            <React.Fragment>
              {modalAddToAccount.show && (
                <ModalAddToAccount
                  show={true}
                  toggle={modalAddToAccountToggle}
                  mode={'congrats'}
                />
              )}
              <MyNavbar />

              {hidePrice && parseFloat(state.ecard.total_usage) > 0 && (
                <Container>
                  <Row className="row-alert">
                    <Col>
                      <Alert variant="success">
                        <FaCheckCircle className="mr-2" />{' '}
                        <strong>Fully redeemed:</strong> Looks like you have
                        fully redeemed all your gifts.
                      </Alert>
                    </Col>
                  </Row>
                </Container>
              )}

              <Hero />
              {state.ecard.is_gift_box == 1 && <GetInspired />}

              <About />
              <MyFooter />
            </React.Fragment>
          )}
      </LoadECard>
    </div>
  )
}

export default GetStarted
