import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { AppContext } from '../states'

const ModalGiftType = (props) => {
  const { state, dispatch } = useContext(AppContext)
  const { modalGiftType } = state
  const { voucher_type } = state.strapiData
  const toggle = () => dispatch({ type: 'SET_MODAL_GIFT_TYPE' })
  let item = {}
  if (voucher_type) {
    voucher_type.forEach((i) => {
      if (i.slug === modalGiftType.type ) {
        item = i
      }
    })
  }
  return (
    <Modal centered show={modalGiftType.show} onHide={toggle}>
      <Modal.Header style={{ paddingBottom: '.5rem' }} closeButton>
        <Modal.Title>
          <h4
            style={{
              marginBottom: '12px',
              marginTop: '1em',
              fontWeight: 'bold',
              fontSize: '21px',
            }}
          >
            {item.title}
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: '0' }}>
        {item.text && (
          <p
            dangerouslySetInnerHTML={{
              __html: item.text.replace(/\n/g, '<br/>'),
            }}
          ></p>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default ModalGiftType
