import React from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { FaChevronRight } from 'react-icons/fa'
import Clamp from 'react-multiline-clamp'
import { Carousel } from 'react-responsive-carousel'
import { useHistory } from 'react-router-dom'

const ModalGift = ({ show, toggle, gifts, activeIndex, setIndex }) => {
  const history = useHistory()
  return (
    <Modal
      size="xl"
      show={show}
      centered
      onHide={() => toggle(false)}
      className="modal-gift-box"
    >
      <Modal.Header closeButton></Modal.Header>
      <div className="p-2">
        <Carousel
          selectedItem={activeIndex}
          onChange={(idx, e) => {
            setIndex(idx)
          }}
          infiniteLoop
          showThumbs={false}
          renderIndicator={false}
          showStatus={false}
        >
          {gifts.map((gift, giftIdx) => (
            <div key={'gift_item_' + giftIdx}>
              <Row className="carousel__content__container">
                <Col md={5} className="d-none d-md-block ">
                  <img className="image-gift" src={gift.file} alt={gift.name} />
                </Col>
                <Col md={7} className="carousel__content">
                  <p className="carousel__subtitle">
                    {gift.merchant && gift.merchant.name}
                  </p>
                  <h4 className="carousel__title">{gift.name}</h4>
                  <hr />
                  <div className="d-sm-block d-md-none">
                    <img
                      className="image-gift mb-3"
                      src={gift.file}
                      alt={gift.name}
                    />
                  </div>
                  {gift.description && (
                    <Clamp withTooltips lines={6} maxLines={100}>
                      <div
                        className="carousel__description"
                        dangerouslySetInnerHTML={{
                          __html: gift.description.replaceAll('\n', '<br/>'),
                        }}
                      ></div>
                    </Clamp>
                  )}
                  <div className="mt-4 mb-4 text-left carousel__cta">
                    <Button
                      size="lg"
                      variant={'outline-primary'}
                      onClick={() => {
                        history.push('/gift/' + gift.id)
                      }}
                    >
                      View Details <FaChevronRight className="ml-2" />{' '}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          ))}
        </Carousel>
      </div>
    </Modal>
  )
}

export default ModalGift
