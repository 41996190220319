import React, { useContext } from 'react'
import { Col, Spinner } from 'react-bootstrap'
import LazyLoad from 'react-lazyload'
import Clamp from 'react-multiline-clamp'
import { Link } from 'react-router-dom'
// import LabelGiftTypeChip from '../../../components/LabelGiftTypeChip';
import MyImg from '../../../components/MyImg'
import { AppContext } from '../../../states'

const ViewByGift = () => {
  const { state } = useContext(AppContext)
  const { giftItems } = state.searchPage
  // eslint-disable-next-line eqeqeq
  const hidePrice = state.ecard && state.ecard.hide_price == 1 ? true : false
  return giftItems.map((gift, idx) => {
    if (
      hidePrice &&
      parseFloat(state.ecard.total_usage) === 0 &&
      parseFloat(gift['min-price']) > parseFloat(state.ecard.balance)
    ) {
      return ''
    }
    return (
      <Col
        className="page-search__item"
        key={'col_' + idx}
        md="3"
        sm="6"
        xs="6"
      >
        <Link title={gift.name} to={'/gift/' + gift.id}>
          <LazyLoad
            classNamePrefix={
              gift.is_cash_voucher === 1 ? 'is-cash lazyload' : 'lazyload'
            }
            placeholder={<Spinner />}
          >
            <div className="merchant-image-wrapper">
              <MyImg
                style={{ borderRadius: '12px' }}
                height="80px"
                bgcolor={gift.is_cash_voucher === 1 ? '#ffffff' : '#02afa1'}
                spinnervariant="light"
                alt={gift.name}
                src={
                  gift.file
                    .replace(
                      'https://web-sandbox.giftano.io/fls',
                      'https://giftano.imgix.net/fls'
                    )
                    .replace(
                      'https://static-cdn.giftano.com/fls',
                      'https://giftano.imgix.net/fls'
                    ) + '?auto=format&width=400'
                }
                rounded
                fluid
                className={
                  'merchant-images top-rad ' +
                  (gift.is_cash_voucher === 1 ? 'cash-voucher-images' : '')
                }
              />
              <div className="placeholder-gift">
                <div className="placeholder-coupon">{gift.name}</div>
                <Clamp withTooltip lines={1}>
                  <div className="placeholder-merchant">{gift.merchant && gift.merchant.name}</div>
                </Clamp>
                {hidePrice ? (
                  <div className="placeholder-price">Read more &gt;&gt;</div>
                ) : // eslint-disable-next-line eqeqeq
                gift.is_cash_voucher == 1 ? (
                  <div className="placeholder-price">
                    From S${gift['min-price']}
                  </div>
                ) : (
                  <div className="placeholder-price">S${gift.price}</div>
                )}
                {/* <div className="placeholder-brand" title={gift.merchant.name}>
                      {gift.merchant.name}
                    </div> */}
                {/* <LabelGiftTypeChip tags={gift.tags_v2} /> */}
              </div>
            </div>
          </LazyLoad>
        </Link>
      </Col>
    )
  })
}
export default ViewByGift
