import React, { useContext } from 'react'
import { Button, Col, Modal, ModalTitle, Row } from 'react-bootstrap'
import { FaSpinner } from 'react-icons/fa'
import { AppContext } from '../../../states'

const ModalConfirm = ({ confirmAction }) => {
  const { state, dispatch } = useContext(AppContext)
  const { showConfirm, isRedeem, gifts, activeMerchant } = state.merchantPage
  // eslint-disable-next-line eqeqeq
  const hidePrice = state.ecard && state.ecard.hide_price == 1 ? true : false
  const grand_total =
    gifts.length > 0
      ? gifts
          .map(
            (gift) =>
              gift.quantity * parseFloat(gift.price) +
              (gift.quantity ? parseFloat(gift.primary_extrafirst) : 0)
          )
          .reduce((total, price) => total + price)
      : 0
    console.log('state', state)
  return (
    <Modal
      show={showConfirm}
      onHide={() => {
        dispatch({
          type: 'MERCHANT_PAGE',
          payload: {
            showConfirm: false,
          },
        })
      }}
      centered
      className="modal-confirm"
      backdrop="static"
    >
      <Modal.Header closeButton={!isRedeem}>
        <img
          className="icon my-4"
          src={'https://static-cdn.giftano.com/assets/how-to-redeem-title.png'}
          alt="How to purchase"
        />
        <ModalTitle className="text-giftano-orange px-lg-5 title">
          Are you sure you want to purchase this gift?
          {hidePrice ? (
            <p className="description  mt-4">
              Strictly no exchange or refund allowed.
            </p>
          ) : (
            <p className="description  mt-4">
              Strictly no exchange or refund allowed once redeemed.
            </p>
          )}
        </ModalTitle>
      </Modal.Header>
      {!hidePrice && (
        <Modal.Body className="m-3" style={{boxShadow: '0px 4px 20px rgba(92, 92, 92, 0.1)', borderRadius: '4px'}}>
          {gifts
            .filter((gift) => gift.quantity > 0)
            .map((item, idx) => (
              <Row key={'confirm_item_' + idx} style={{padding: '15px'}}>
                <Col xs="9" md="7">
                  <img src={item.file} alt={item.name} style={{height: '40px'}}/>
                  <p style={{ margin: '0', color: '#E3A25E' }}>
                    {activeMerchant && activeMerchant.name}
                  </p>
                  <p style={{ margin: '0' }}>{item.name}</p>
                  {/* <p style={{ margin: '0', color: '#E3A25E' }}>
                    S${item['price']}
                  </p> */}
                </Col>
                <Col xs="3" md="2" className="text-right d-flex align-items-end">
                  x {item.quantity}
                </Col>
                <Col md="3" className="text-right d-none d-md-flex align-items-end ">
                  S$
                  {(item.quantity * item.price).toLocaleString('en', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Col>
              </Row>
            ))}
          <Row>
            <Col className="mt-3 ">
              <div className="font-weight-bold d-flex justify-content-between merchant__total-order">
                Total Order:
                <span className="attention-quantity ml-3">
                  S$
                  {grand_total.toLocaleString('en', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      )}
      <Modal.Footer className="mb-4 text-center">
        <Button
          variant="neutral"
          onClick={() => {
            // setShowConfirm(false)
            dispatch({
              type: 'MERCHANT_PAGE',
              payload: {
                showConfirm: false,
              },
            })
          }}
          className="btn-modal-cta"
          disabled={isRedeem}
        >
          No
        </Button>
        <Button
          onClick={confirmAction}
          disabled={isRedeem}
          className="btn-modal-cta"
          variant="primary"
        >
          {isRedeem && <FaSpinner className="mr-2 icon-spin" />}
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalConfirm
