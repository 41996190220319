import React, { useContext } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { AppContext } from "../../../states";


const ModalMerchantTnc = () => {
    const { state, dispatch } = useContext(AppContext);
    const { showModalTnc, activeMerchant } = state.merchantPage;
    const onHide = () => {
        dispatch({
            type: 'MERCHANT_PAGE', payload: {
                showModalTnc: false
            }
        })
    }
    return (
        <Modal
            centered
            show={showModalTnc}
            onHide={onHide}
            className="modal-terms"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {activeMerchant.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md="12">
                        <h5
                            style={{
                                marginTop: '1.5em',
                                marginBottom: '12px',
                                fontWeight: 'bold',
                            }}
                        >
                            Terms &amp; Condition
                        </h5>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: activeMerchant.tnc,
                            }}
                        ></div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalMerchantTnc;