import React, { useContext } from 'react';
import { Col, Image, Spinner } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import MyImg from '../../../components/MyImg';
import { AppContext } from '../../../states';
import PlaceholderImage from '../../../assets/images/placeholder-merchant.png'
import { MdCardGiftcard } from 'react-icons/md';

const ViewByBrand = () => {
    const {state} = useContext(AppContext);
    const {merchants} = state.searchPage;

    return merchants.map((merchant, idx) => (
      <Col
        className="page-search__item"
        key={'col_' + idx}
        md="4"
        lg="3"
        sm="6"
        xs="6"
      >
        <Link title={merchant.name} to={'/merchant/' + merchant.id}>
          <LazyLoad placeholder={<Spinner />}>
            {merchant.logo ? (
              <MyImg
                height="108px"
                spinnervariant="light"
                alt={merchant.name}
                src={
                  merchant.logo
                    .replace(
                      'https://web-sandbox.giftano.io/fls',
                      'https://giftano.imgix.net/fls'
                    )
                    .replace(
                      'https://static-cdn.giftano.com/fls',
                      'https://giftano.imgix.net/fls'
                    ) + '?auto=format&width=400'
                }
                rounded
                fluid
                className="merchant-images"
              />
            ) : (
              <>
                <Image
                  alt={merchant.name}
                  title={merchant.name}
                  src={PlaceholderImage}
                  rounded
                  fluid
                  className="merchant-images top-rad"
                />
                <div className="placeholder">
                  <div className="placeholder-icon">
                    <MdCardGiftcard color="white" size="2em" />
                  </div>
                  <div className="placeholder-merchant">{merchant.name}</div>
                </div>
              </>
            )}
          </LazyLoad>
        </Link>
      </Col>
    )
  )
}

export default ViewByBrand