import Axios from 'axios';
import React, { useContext } from 'react';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { FaRegAddressBook, FaRegMap, FaRegUserCircle, FaSpinner } from 'react-icons/fa';
import { FiSmartphone } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PostPublic, PostVCR } from '../../../services/Connection';
import { AppContext } from '../../../states';

const ModalShipment = () => {
    const { state, dispatch } = useContext(AppContext);
    const {
        showModalShipment,
        isRedeem,
        gifts,
        activeMerchant
    } = state.merchantPage;
    const history = useHistory();

    const formCourierSubmit = async (e) => {
        e.preventDefault()

        let fd = new FormData(e.target)

        // console.log('process redeem')

        let selectedGifts = gifts.filter((gift) => gift.quantity > 0)

        let country = selectedGifts[0].country.code
        let currency = selectedGifts[0]['currency-code']

        // setIsRedeem(true)
        dispatch({
            type: 'MERCHANT_PAGE', payload: {
                isRedeem: true
            }
        });

        // get next delivery date
        let nextDeliveryDate = ''
        let respNext = await PostPublic('next_delivery_date/')
        // console.log('next resp', respNext)
        // eslint-disable-next-line eqeqeq
        if (respNext.status == 1) {
            nextDeliveryDate = respNext.data
        } else {
            toast('Error get delivery date, please try again!', { type: 'warning' })
            return
        }

        // get shopping cart id
        let resp = await PostPublic('get_shoppingcartid/', {
            marketCountry: country,
            currency: currency,
            'sales-channel': 'giftano-card-redemption',
        })
        // console.log('shopping cart id ', resp)
        if (resp.status === 1) {
            let shoppingcartid = resp.data.id

            for (let gift of selectedGifts) {
                let respAddCartItem = await PostPublic('add_shoppingcartitem/', {
                    merchantid: activeMerchant.id,
                    shoppingcartid: shoppingcartid,
                    dymethod: gift.dymethods.map((d) => d.value).includes('mail')
                        ? 'mail'
                        : 'courier',
                    dydate: nextDeliveryDate,
                    quantity: gift.quantity,
                    vouchertype: gift.id,
                    attentionto: fd.get('attentionto'),
                    recipientmail: fd.get('recipientmail'),
                    recipientpostalcode: fd.get('recipientpostalcode'),
                    recipientphonenumber: fd.get('recipientphonenumber'),
                })
                // eslint-disable-next-line eqeqeq
                if (respAddCartItem.status == 0) {
                    toast(
                        'Can not add gift to shopping cart, ' + respAddCartItem.message,
                        { type: 'warning' }
                    )
                    // console.log('result add cart ', respAddCartItem)
                    // setIsRedeem(false)
                    dispatch({
                        type: 'MERCHANT_PAGE', payload: {
                            isRedeem: false
                        }
                    });
                    return
                }
            }

            //lock the cart
            await PostPublic('lock_shoppingcart/', {
                shoppingcartid: shoppingcartid,
            })
            // console.log('resp lock shopping cart', respLock)

            //get ip client
            let ipClient = ''
            try {
                let respIp = await Axios('https://api.ipify.org/?format=json')
                // console.log('respIp', respIp)
                if (respIp.status === 200) {
                    ipClient = respIp.data.ip
                }
            } catch (err) { }

            //pay
            let respPay = await PostVCR('createtransaction_with_ecard/', {
                ecard: state.ecard.number,
                shoppingcartid: shoppingcartid,
                ipaddress: ipClient,
            })
            if (respPay.status === 1) {
                //success
                let receipt = respPay.data
                history.push('/mygifts/' + receipt)
            } else {
                if (respPay.message) {
                    toast('Unable to process redemption, ' + respPay.message, {
                        type: 'warning',
                    })
                } else {
                    toast(
                        'Unable to process redemption, please try again or contact Gitano customer support.',
                        { type: 'warning' }
                    )
                }
                // console.error('error create transaction')
            }
        } else {
            if (resp.message) {
                toast('Unable to process redemption, ' + resp.message, {
                    type: 'warning',
                })
            } else {
                toast(
                    'Unable to process redemption, please try again or contact Gitano customer support.',
                    { type: 'warning' }
                )
            }
            // console.error('error get shopping cart')
        }

        // setIsRedeem(false)
        dispatch({
            type: 'MERCHANT_PAGE', payload: {
                isRedeem: false
            }
        });
    }

    return (
        <Modal
            show={showModalShipment}
            onHide={() => {
                // setShowModal(false)
                dispatch({
                    type: 'MERCHANT_PAGE', payload: {
                        showModalShipment: false
                    }
                })
            }}
            centered
            className="modal-shipment"
        >
            <Modal.Body className="m-3">
                <Form onSubmit={formCourierSubmit}>
                    <Form.Group>
                        <Form.Label>Attention To</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    {' '}
                                    <FaRegUserCircle />{' '}
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                placeholder="Name"
                                name="attentionto"
                                required
                                autoFocus
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Delivery Address</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    {' '}
                                    <FaRegAddressBook />{' '}
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                placeholder="Full address with unit number"
                                name="recipientmail"
                                required
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Postal Code</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    {' '}
                                    <FaRegMap />{' '}
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                placeholder="Postal Code"
                                name="recipientpostalcode"
                                required
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Recipient Phone</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    {' '}
                                    <FiSmartphone />{' '}
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                placeholder="Valid phone number"
                                name="recipientphonenumber"
                                required
                            />
                        </InputGroup>
                    </Form.Group>
                    <Button
                        type="submit"
                        className="btn-send-gift"
                        block
                        disabled={isRedeem}
                    >
                        {isRedeem && <FaSpinner className="mr-2 icon-spin" />}
                        SEND GIFT
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default ModalShipment;