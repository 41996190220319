import React from 'react'
import ContentLoader from 'react-content-loader'

const CardListSkeletonMobile = (props) => (
  <ContentLoader
    backgroundColor="var(--gf-neutral-200)"
    foregroundColor="var(--gf-light-gray-200)"
    {...props}
  >
    <rect x="0" y="0" rx="2" ry="2" width="100%" height="20%"></rect><rect x="0" y="25%" rx="4" ry="4" width="100%" height="10%"></rect><rect x="0" y="40%" rx="4" ry="4" width="100%" height="40%"></rect><rect x="0" y="85%" rx="4" ry="4" width="100%" height="10%"></rect>
  </ContentLoader>
)

export default CardListSkeletonMobile


