import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

const ModalHowToRedeem = ({ show, toggle }) => {
  const history = useHistory()
  return (
    <Modal show={show} onHide={toggle} className="modal-how-to-redeem" centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <img
          className="icon my-4 "
          src={'https://static-cdn.giftano.com/assets/how-to-redeem-title.png'}
          alt="How to redeem"
        />
        <h4 className="title mb-3">How to redeem your gift</h4>
        <p class="description my-4">
          Browse through the amazing gifts inside this gift box &amp; select
          your favourite gift!
        </p>
        <img
          className="img-fluid"
          src={'https://static-cdn.giftano.com/assets/how-to-redeem.png'}
          alt="How to redeem"
        />
        <p class="description my-4">
          Redeem your experience gift at the merchant establishment or choose to
          have your physical gift item delivered to you.
        </p>
        <Button
          onClick={() => {
            history.push('/how-to-redeem')
          }}
          className="btn-modal-cta mr-2"
        >
          Learn More
        </Button>
        <Button
          variant="outline-primary"
          className="btn-modal-cta"
          onClick={toggle}
        >
          Ok
        </Button>
      </Modal.Body>
    </Modal>
  )
}

export default ModalHowToRedeem
