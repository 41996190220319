import Axios from 'axios';
import qs from 'qs';

let baseUrlPublic = '/internal/public/';
let baseUrlMerchant = '/internal/merchant/';
let baseUrlVCR = '/internal/vcr/';
let baseUrlMisc = '/internal/misc/';
let baseUrlPayment = '/api/' + process.env.REACT_APP_PATH_PAYMENT;

export const PostPublic = async(url, data = null, gn = '', cn = 'unknown') => {
    //try to acces via proxy cloudflare
    try{
        let resp = await Axios.post(baseUrlPublic + url, qs.stringify({...data}));
        if(resp.status === 200){
            return resp.data;
        }
    }catch(err){
        //error access cloudflare logs here
        console.log('error from cloudflare', err);
        throw err;
    }
}

export const PostMerchant = async(url, data = null, gn = '', cn = 'unknown') => {
    //try to acces via proxy cloudflare
    try{
        let resp = await Axios.post(baseUrlMerchant + url, qs.stringify({...data}));
        if(resp.status === 200){
            return resp.data;
        }
    }catch(err){
        //error access cloudflare logs here
        console.log('error from cloudflare', err);
        throw err;
    }
}

export const PostVCR = async(url, data = null) => {
    //try to acces via proxy cloudflare
    try{
        let resp = await Axios.post(baseUrlVCR + url, qs.stringify({...data}));
        if(resp.status === 200){
            return resp.data;
        }
    }catch(err){
        //error access cloudflare logs here
        console.log('error from cloudflare', err);
        throw err;
    }
}

export const PostMISC = async(url, data = null) => {
    //try to acces via proxy cloudflare
    try{
        let resp = await Axios.post(baseUrlMisc + url, qs.stringify({...data}));
        if(resp.status === 200){
            return resp.data;
        }
    }catch(err){
        //error access cloudflare logs here
        console.log('error from cloudflare', err);
        throw err;
    }
}

export const RequestPayment = async (url, method= 'GET', data = {}, headers = {}) => {
    try{
        let resp = await Axios({
            url: baseUrlPayment + url,
            method: method,
            data: data,
            headers : headers
        });
        if(resp.status === 200){
            return resp.data;
        }
    }catch(err){
        console.log('error from gcp', err);
        throw err;
    }
}

export const Connection = async ({url, method='GET', data={}, headers = {}}) => {
    try{
        let resp = await Axios({
            url: '/api/' + url ,
            method: method,
            data: data,
            headers: headers
        });
        if(resp.status === 200){
            return resp.data;
        }
    }catch(err){
        console.log('error connection', err);
    }
    return null;
}

export const RecordLog = async (notes, gn = '') => {
    // const host = window.location.hostname;
    // let logurl = '';
    // if(host === 'cards.giftano.com'){
    //     logurl = 'https://cards.giftano.com/recordlogs';
    // }else{
    //     logurl = 'https://cards-sandbox.giftano.io/recordlogs'
    // }
    let logurl = '/api/recordlogs';
    Axios.post(logurl, qs.stringify({
        notes: notes,
        gn: gn 
    })).then(res => {
        console.log('error log sent', res);
    }).catch(errLog => {
        console.log('error log failed to sent', errLog);
    });
}