import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
  Tab,
  Tabs,
} from 'react-bootstrap'
import { AppContext } from '../states'
import braintree from 'braintree-web'
import DropIn from 'braintree-web-drop-in-react'
import './ModalTopUp.scss'
import { PostPublic, PostVCR, RequestPayment } from '../services/Connection'
import { toast } from 'react-toastify'
import { ErrorMessages } from '../components/ErrorMessages'
import PhoneInput from 'react-phone-input-2'


const initialFormGiftanoCard = {
  number: '',
  amount: 0,
  phone : '65',
  verification : '',
  is_verify_sent : false
};

const ModalTopUp = (props) => {
  const { state, dispatch } = useContext(AppContext)
  const { modalTopupShow, topupAmount, topupAction } = state

  const [isSending, setIsSending] = useState(false)

  const [amount, setAmount] = useState(0)
  useEffect(() => {
    if (topupAmount) {
      setAmount(topupAmount)
    }
  }, [topupAmount])
  const amountRef = useRef()
  const [braintreeInstance, setBraintreeInstance] = useState()
  const [braintreeDeviceData, setBraintreeDeviceData] = useState()
  const [braintreeDropinOptions, setBraintreeDropinOptions] = useState({})

  const [formGiftanoCard, setFormGiftanoCard] = useState(initialFormGiftanoCard);

  useEffect(() => {
    // if(modalTopupShow && !braintreeDropinOptions.authorization)
    RequestPayment('/paymentgateway-client-token/5eda4587e5aec85cd1a5e35c').then(
      (json) => {
        if (json.code === 200) {
          // setBraintreeClientToken(json.data.client_token);
          let btOpt = {
            authorization: json.data.client_token,
            threeDSecure: true,
            dataCollector: true,
            card: {
              cardholderName:{
                required:true
              }
            },
          }

          // apply options
          // console.log('bt options', btOpt)
          setBraintreeDropinOptions(btOpt)
        }
      }
    ).catch(() => {
      // error fetch payment gateway
    });
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (amountRef.current) {
        amountRef.current.focus()
      }
    }, 200)
  },[])

  // useEffect(()=>{
  //   if(modalTopupShow){
  //     setFormGiftanoCard(initialFormGiftanoCard);
  //   }
  // },[modalTopupShow])

  const loadEcard = () => {
    PostPublic('ecard/', {
      ecard: state.gcardinfo.number,
      key: state.key,
      details: '1',
    })
      .then((respEcard) => {
        // console.log('resp ecard/', respEcard);
        if (respEcard.status === 1) {
          dispatch({ type: 'SET_ECARD', data: respEcard.data })
          if (topupAction) {
            topupAction()
          }
        }
      })
      .catch((errEcard) => {
        toast.warning(
          ErrorMessages.error_refresh_balance
        )
      })
  }

  const onBraintreeInstance = (i) => {
    // console.log('instance set', i)
    setBraintreeInstance(i)
    if (i && i._client) {
      braintree.dataCollector
        .create({
          client: i._client,
          kount: true,
          paypal: true,
        })
        .then((res) => {
          //console.log('result collector', res);
          setBraintreeDeviceData(res.deviceData)
        })
        .catch((err) => {
          //console.log('collector error', err);
        })
    }
  }

  const topUpClick = async () => {
    if(!braintreeInstance) return;
    braintreeInstance.requestPaymentMethod({
      threeDSecure: {
        amount: parseFloat(amount),
      }
    },(err, payload) => {
      if (err) {
        return
      }
      setIsSending(true);
      
      RequestPayment(
        '/paymentgateway-transaction/5eda4587e5aec85cd1a5e35c',
        'POST',
        {
          amount: parseFloat(amount),
          payment_method_nonce: payload.nonce,
          device_data: braintreeDeviceData,
          order_id: 'topup_' + state.ecard.number,
        }
      )
        .then((json) => {
          //console.log('json res', json);
          if (json.code === 201) {
            //call topup endpoint
            PostVCR('giftano-card/top-up/', {
              braintree_id: json.Data.transaction_id,
              amount: parseFloat(amount),
              card_number: state.ecard.number,
            })
              .then((resultTopup) => {
                // console.log('result topup', resultTopup)

                if (resultTopup.status === 1) {
                  toast.success('Top-up Success.')
                  // need reload giftano card
                  loadEcard();
                  setIsSending(false)
                  dispatch({ type: 'HIDE_TOPUP' })
                } else {
                  toast.warning('Top-up error ' + resultTopup.message)
                  loadEcard();
                  setIsSending(false)
                  dispatch({ type: 'HIDE_TOPUP' })
                }
              })
              .catch((errorTopup) => {
                console.log('error topup', errorTopup)
                toast.error('Error process top-up, please try again!')
                setIsSending(false)
                loadEcard();
                dispatch({ type: 'HIDE_TOPUP' })
              })
          } else {
            //
            toast.warning('Error process payment, ' + json.error)
            setIsSending(false)
            loadEcard();
            dispatch({ type: 'HIDE_TOPUP' })
          }
        })
        .catch((err) => {
          console.log('error', err)
          toast.error('Error process payment, please try again..!')
          setIsSending(false)
          loadEcard();
          dispatch({ type: 'HIDE_TOPUP' })
        })

    }); // endof request payment method from braintree
  }

  const topupFromGCard = async (e) => {
    e.preventDefault();
    if(formGiftanoCard.is_verify_sent){
      // topup
      setIsSending(true);
      try{
        let res = await PostVCR('giftano-card/transfer-balance/',{
          amount: formGiftanoCard.amount,
          card_number_origin: formGiftanoCard.number.replace(/ /g,''),
          card_number_destination: state.ecard.number,
          phonenumber: "+" + formGiftanoCard.phone,
          token: formGiftanoCard.verification,
          ipaddress: state.ipaddress
        })
        if(res.status === 1){
          toast.success('Transfer Balance Success');
          loadEcard();
          dispatch({ type: 'HIDE_TOPUP' })
        }else{
          toast.error(res.message || ErrorMessages.error_top_up);
        }
      }catch(err){
        toast.error(ErrorMessages.error_network);
      }
      setIsSending(false);
    }else{
      // send verification 
      setIsSending(true);
      try{
        let res = await PostVCR('giftano-card/get-otp/',{
          card_number : formGiftanoCard.number.replace(/ /g,''),
          phonenumber : "+" + formGiftanoCard.phone,
          ipaddress : state.ipaddress
        })
        // console.log('Response from get otp', res);
        if(res.status === 1){
          setFormGiftanoCard({...formGiftanoCard, is_verify_sent: true});
        }else{
          toast.error(res.message || ErrorMessages.error_get_otp)
        }
      }catch(err){
        toast.error(ErrorMessages.error_network);
      }
      setIsSending(false);
    }
  }


  if (!state.ecard) return ''

  return (
    <Modal
      show={modalTopupShow}
      centered
      onHide={() => dispatch({ type: 'HIDE_TOPUP' })}
      className="top-up-modal"
      backdrop={'static'}
    >
      <Modal.Header closeButton={!isSending}>
        <Modal.Title>
          <div className="top-up-title">{state.topupAction ? "Top Up and Buy" : "Add Credits"}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(state.member && state.member.id) ? (
          <Tabs defaultActiveKey="credit_card">
            <Tab title={state.strapiData.allow_transfer ? "Credit Card" : undefined} eventKey="credit_card">
              <div className="top-up-container">
                <Form.Group as={Row}>
                  <Form.Label column sm="6">
                    Balance
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control disabled value={state.ecard.balance} />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="6">
                    Add Credits
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      ref={amountRef}
                      value={amount}
                      onChange={(e) => {
                        let bal = parseFloat(state.ecard.balance);
                        let amnt = parseFloat(e.target.value);
                        if(isNaN(amnt)) amnt = 0;
                        if(bal + amnt > 1000){
                          amnt = 1000 - bal;
                        }
                        if(amnt > 200){
                          amnt = 200;
                        }
                        setAmount(amnt)
                      }}
                      autoFocus={state.topupAction ? false : true}
                      readOnly={state.topupAction ? true : false}
                    />
                  </Col>
                </Form.Group>
              </div>

              <div className="mt-2 mb-3">
                {!braintreeInstance && (
                  <Spinner
                    animation="border"
                    variant="primary"
                    className="mt-4 mb-2"
                  />
                )}
                <DropIn
                  options={braintreeDropinOptions}
                  onInstance={onBraintreeInstance}
                />
              </div>

              <Button
                disabled={isSending}
                variant="giftano"
                className="btn-top-up-submit"
                block
                onClick={topUpClick}
              >
                {isSending ? (
                  <>
                    <Spinner animation="border" size="sm" className="mr-2" />
                    Process...
                  </>
                ) : (
                  <>{state.topupAction ? 'TOP UP AND BUY NOW' : 'ADD CREDITS NOW'}</>
                )}
              </Button>
            </Tab>
            {state.strapiData.allow_transfer && (
              <Tab title="Giftano Card" eventKey="giftano_card">
                <div className="top-up-container">
                    <Form onSubmit={topupFromGCard} className="ml-4 mr-4">
                      <Row>
                        <Col md="6">
                          <Form.Group>
                            <Form.Label>From</Form.Label>
                            <Form.Control disabled={formGiftanoCard.is_verify_sent} required value={formGiftanoCard.number} onChange={(e)=>setFormGiftanoCard({...formGiftanoCard, number: e.target.value})} />
                          </Form.Group>  
                        </Col>
                        <Col md="6">
                          <Form.Group>  
                            <Form.Label>To</Form.Label>
                            <Form.Control disabled value={state.ecard.number} />
                          </Form.Group>  
                        </Col>
                      </Row>
                      
                      <Form.Group>
                        <Form.Label>Amount to transfer</Form.Label>
                        <Form.Control disabled={formGiftanoCard.is_verify_sent} type="text" required value={formGiftanoCard.amount} onChange={(e)=>{
                          let bal = parseFloat(state.ecard.balance);
                          let amount = parseFloat(e.target.value);
                          if(isNaN(amount)) amount = 0;
                          if(bal + amount > 1000){
                            amount = 1000 - bal;
                          }
                          //console.log(amount);
                          setFormGiftanoCard({...formGiftanoCard, amount: amount})
                        }} />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Phone Number</Form.Label>
                        <PhoneInput
                          disabled={formGiftanoCard.is_verify_sent}
                          required
                          country={'sg'}
                          value={formGiftanoCard.phone}
                          onChange={phone => setFormGiftanoCard({...formGiftanoCard, phone: phone})}
                          enableSearch
                        />
                      </Form.Group>
                      {formGiftanoCard.is_verify_sent && (
                      <Card>
                        <Card.Body>
                          <Form.Group as={Row}>
                            <Form.Label column md="7">Enter Verification Number :</Form.Label>
                            <Col md="5">
                              <Form.Control value={formGiftanoCard.verification} onChange={(e)=>setFormGiftanoCard({...formGiftanoCard, verification: e.target.value})}/>
                            </Col>
                          </Form.Group>
                        </Card.Body>
                      </Card>
                      )}
                      <div className="mt-4 mb-4">
                        <Button 
                          type="submit" 
                          block
                          disabled={isSending}
                          >
                            {formGiftanoCard.is_verify_sent ? 'Verify and Top Up Now' : 'Verify Phone'}
                        </Button>
                      </div>
                    </Form>
                </div>
              </Tab>
            )}
          </Tabs>
        ):(
          <div className="pb-4">
            <h4>Please sign-in to access top-up feature</h4>
          </div>
        )}

      </Modal.Body>
    </Modal>
  )
}

export default ModalTopUp
