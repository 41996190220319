/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react'
import { FaSpinner } from 'react-icons/fa'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ErrorMessages } from '../components/ErrorMessages'
import { PostPublic } from '../services/Connection'
import { AppContext } from '../states'
import PouchDB from 'pouchdb'
import Axios from 'axios'
import { Card } from 'react-bootstrap'

const params = new URLSearchParams(window.location.search)

const Loading = () => {
  const { state, dispatch } = useContext(AppContext)
  const { gcard_number, gcard_pin } = useParams()
  const [showError, setShowError] = useState(false)
  const history = useHistory()

  let forceShowAnimation = false
  if (params.has('animation')) {
    forceShowAnimation = params.get('animation') === 'show'
  }
  useEffect(() => {
    const loadWhitelabel = async () => {
      try {
        let resp = await PostPublic('whitelabel-theme/', {
          slug: window.location.hostname.substr(
            0,
            window.location.hostname.indexOf('.')
          ),
        })
        if (!resp.error && resp.status === 1) {
          // console.log('set global whitelabel');
          dispatch({ type: 'SET_WHITELABEL', data: resp.data })
          history.push('/passcode')
        } else {
          toast.error(ErrorMessages.error_get_whitelabel)
          history.push('/passcode')
        }
      } catch (err) {
        toast.error(ErrorMessages.error_network)
        // RecordLog('Error load whitelabel on loading fn loadWhitelabel - ' + err.message);
        setShowError(true)
      }
    }
    const loadGCardInfo = async () => {
      try {
        // remove old local storage
        // localStorage.clear();
        localStorage.removeItem('invitedMember')
        localStorage.removeItem('redemption-data-key')
        localStorage.removeItem('redemption-data-whitelabel')
        localStorage.removeItem('redemption-data-gcardinfo')
        let resCardInfo = await PostPublic('gcard/info/', {
          ecard: gcard_number,
        })
        if (resCardInfo.status === 1) {
          let login_required = false
          const cardNeedMemberAccount = resCardInfo.data.need_member_account
          const strapiNeedMemberAccount =
            state.strapiData.member_account_required

          // eslint-disable-next-line eqeqeq
          if (cardNeedMemberAccount == 9) {
            // use strapi
            login_required = strapiNeedMemberAccount
          } else {
            // eslint-disable-next-line eqeqeq
            login_required = cardNeedMemberAccount == 1 ? true : false
          }

          if (login_required && !state.session_id) {
            //no session id
            const redirect_auth = process.env.REACT_APP_REDIRECT_AUTH
            const locationWindow =
              window.location.protocol +
              '//' +
              window.location.host +
              window.location.pathname +
              '?'
            const redirect_url =
              redirect_auth +
                '?redirect=' +
                btoa(locationWindow + '|member') +
                ((state.ecard && state.ecard.is_gift_box == 1)
                ? '&mode=6'
                : '&mode=3') + '&rb='+ btoa('https://giftano.com')
            window.location = redirect_url
            return
          }

          // destroy prev search gifts result
          try {
            let db = new PouchDB(
              'redemption-gift-result-' + resCardInfo.data.company.id
            )
            await db.destroy()
          } catch (err) {}

          dispatch({ type: 'SET_GCARD_INFO', data: resCardInfo.data })
          //cek pin available
          if (gcard_pin) {
            let resVerify
            try {
              resVerify = await PostPublic('gcard/verify_pin/', {
                ecard: resCardInfo.data.number,
                pin: gcard_pin,
              })
            } catch (err) {
              toast(
                'Failed to verify url, please enter your passcode manually!',
                { type: 'error' }
              )
              history.push('/passcode')
              return
            }
            // console.log('res verify', resVerify)
            if (resVerify.status === 1) {
              dispatch({ type: 'SET_KEY', data: resVerify.data.key })

              //add activate
              if (resCardInfo.data.status === 'inactive') {
                let resActivate = await PostPublic('gcard/activate/', {
                  ecard: resCardInfo.data.number,
                  key: resVerify.data.key,
                })
                if (resActivate.status === 1) {
                  dispatch({
                    type: 'GETSTARTED_PAGE',
                    payload: { giftBoxAnimation: true },
                  })
                  history.push('/start')
                } else {
                  toast.error(
                    resActivate.message || ErrorMessages.error_activate_card
                  )
                }
              } else {
                if (forceShowAnimation) {
                  dispatch({
                    type: 'GETSTARTED_PAGE',
                    payload: { giftBoxAnimation: true },
                  })
                  history.push('/start')
                } else {
                  history.push('/getstarted')
                }
              }
            } else {
              toast.error(resVerify.message || ErrorMessages.error_redeem_url)
              history.push('/passcode')
              // toast.error(ErrorMessages.error_verify_passcode);
            }
          } else {
            //else
            history.push('/passcode')
          }
        } else {
          // toast.error(ErrorMessages.error_giftano_card_not_found);
          toast.error(resCardInfo.message || ErrorMessages.error_redeem_url)
          loadWhitelabel()
          history.push('/passcode')
        }
      } catch (err) {
        toast.error(ErrorMessages.error_network)
        // RecordLog('Error load gcard info on loading fn loadGCardInfo - ' + err.message, gcard_number )
        setShowError(true)
      }
    }
    if (gcard_number) {
      //load gcard number only if strapi loaded
      if (state.strapiData.id) {
        loadGCardInfo()
      }
    } else {
      //load whitelabel
      loadWhitelabel()
    }
  }, [dispatch, forceShowAnimation, gcard_number, gcard_pin, history, state.ecard, state.session_id, state.strapiData.id, state.strapiData.member_account_required, state.strapiData.membership])
  useEffect(() => {
    // const host = window.location.hostname;
    // let url = '';
    // if(host === 'cards.giftano.com'){
    //     url = 'https://cards.giftano.com/ipinfo';
    // }else{
    //     url = 'https://cards-sandbox.giftano.io/ipinfo';
    // }
    let url = '/api/ipinfo'
    Axios(url)
      .then((respIp) => {
        if (respIp.status === 200) {
          //    alert(respIp.data);
          dispatch({ type: 'SET_IPADDRESS', data: respIp.data })
        }
      })
      .catch((err) => {})
  }, [dispatch])
  // console.log(state);
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        fontSize: showError ? '18px' : '28px',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: 'gray',
        }}
      >
        {showError ? (
          <Card style={{ background: '#DAF3F1' }} text="dark">
            <Card.Body>
              {state.strapiData &&
              state.strapiData.error_message_before_start ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: state.strapiData.error_message_before_start,
                  }}
                ></div>
              ) : (
                <>
                  <h2>Unable to open Giftano Card?</h2>
                  Please try to following steps:
                  <ul>
                    <li>Make sure your are not using VPN</li>
                    <li>
                      Please try to access it using another browser or on
                      another device
                    </li>
                  </ul>
                </>
              )}
            </Card.Body>
          </Card>
        ) : (
          <>
            <FaSpinner className="icon-spin mr-2" /> Loading...
          </>
        )}
      </div>
    </div>
  )
}

export default Loading
