/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Row,
} from 'react-bootstrap'
import MyNavbar from '../../components/MyNavbar'
// import { BiChevronLeft } from 'react-icons/bi'
import { FaArrowLeft, FaArrowRight, FaSpinner } from 'react-icons/fa'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'
import { AppContext } from '../../states'
import { PostPublic, PostVCR } from '../../services/Connection'
import { toast } from 'react-toastify'
import Axios from 'axios'
// import ClampLines from 'react-clamp-lines'
import PouchDB from 'pouchdb'
import MyFooter from '../../components/MyFooter'
import { AppConfig } from '../../config'
import { Helmet } from 'react-helmet'
import { useInView } from 'react-intersection-observer'
import ModalVoucherDesc from '../ModalVoucherDesc'
import LoadECard from '../../components/LoadECard'
import { GetShippingCost, loadGiftById, loadGifts } from '../../services/LegacyService'
import ModalConfirm from './components/ModalConfim'
import ModalShipment from './components/ModalShipment'
import MerchantInfo from './components/MerchantInfo'
import ModalMerchantTnc from './components/ModalMerchantTnc'
import Gifts from './components/Gifts'
import MyCart from './components/MyCart'
import ScrollToTop from 'react-scroll-to-top'
import OtherGifts from './components/OtherGifts'
import CardListSkeleton from './components/CardListSkeleton'
import CardListSkeletonMobile from './components/CardListSkeletonMobile'
import { BiArrowToTop } from 'react-icons/bi'
// import Loading from '../Loading'

const Merchant = () => {
  const { state, dispatch } = useContext(AppContext)
  const { merchantId, giftId } = useParams()
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(true)

  const { isRedeem, gifts, activeMerchant, titlePage } = state.merchantPage
  // const { inspiredData } = state.getStartedPage

  const [ref, inView] = useInView({
    rootMargin: '-100px 0px',
  })

  useEffect(() => {
    dispatch({
      type: 'MERCHANT_PAGE',
      payload: {
        showConfirm: false,
      },
    })
    if (state.gifts) {
      // console.log('state gifts', state.gifts)
      const merchantGifts = state.gifts.filter((gift) => {
        if (merchantId) return gift.merchant.id === merchantId
        else return gift.id === giftId
      })
      let initialGifts = []
      merchantGifts.forEach((gift) => {
        if (gift.is_cash_voucher === 1 && gift.products) {
          gift.products.forEach((p) => {
            initialGifts.push({ ...p, quantity: 0 })
          })
        } else {
          initialGifts.push({ ...gift, quantity: 0 })
        }
      })
      // console.log('search result for initial gift', initialGifts, merchantId, giftId)
      // setGifts(initialGifts)
      dispatch({
        type: 'MERCHANT_PAGE',
        payload: {
          gifts: initialGifts,
        },
      })
      if (merchantGifts && merchantGifts.length > 0) {
        // setActiveMerchant(merchantGifts[0].merchant)
        // setTitlePage(merchantGifts[0].merchant.name + ' - ')
        dispatch({
          type: 'MERCHANT_PAGE',
          payload: {
            activeMerchant: merchantGifts[0].merchant,
            titlePage: merchantGifts[0].merchant.name + ' -',
          },
        })
      }
    }
  }, [dispatch, giftId, merchantId, state.gifts])

  useEffect(() => {
    // console.log('gifts', gifts);
    gifts.forEach((gift, giftIdx) => {
      if (
        gift.primary_delivery === 'mail' ||
        gift.primary_delivery === 'courier'
      ) {
        if (gift.quantity > 0) {
          //alert('get deliver fee');
        }
      }
    })
  }, [gifts])

  useEffect(() => {
    const getGifts = async () => {
      // load gifts from local db
      let allDocs = []
      try {
        let db = new PouchDB('redemption-gift-result-' + state.ecard.company.id)
        let res = await db.allDocs({ include_docs: true })
        allDocs = res.rows.map((i) => i.doc).sort((a, b) => a.price - b.price)
      } catch (errGetFromLocalDB) {
        console.log('error load from local db', errGetFromLocalDB)
      }

      if (allDocs.length === 0) {
        if (giftId) {
          // load from catalog by id
          dispatch({
            type: 'MERCHANT_PAGE',
            payload: {
              gifts: [],
              activeMerchant: {},
              titlePage: '',
            },
          })

          if (state.ecard.is_gift_box == 1) {
            let res = await loadGifts(
              state.ecard &&
                state.ecard.company &&
                state.ecard.company &&
                state.ecard.company.id
                ? state.ecard.company.id
                : '',
              0,
              8,
              state.ecard.vouchertypeid
            )
            setIsLoading(true)
            if (res.status === 1) {
              const giftData = res.data.products.map((prod) => ({
                ...prod,
                merchant: res.data.merchants[prod.merchant_id],
              }))
              dispatch({
                type: 'MERCHANT_PAGE',
                payload: { gifts: giftData },
              })
            } else {
              toast.warning(
                res.message || 'Oops, something wrong while load gifts'
              )
            }
            setIsLoading(false)
          } else {
            loadGiftById(state.ecard.company.id, giftId).then((resVoucher) => {
              // console.log('res voucher', resVoucher)
              if (resVoucher.status === 1) {
                let products = resVoucher.data.products.map((product) => {
                  let item = { ...product }
                  item.merchant = resVoucher.data.merchants[product.merchant_id]
                  return item
                })
                let initialGifts = []
                products.forEach((gift) => {
                  if (gift.is_cash_voucher === 1) {
                    gift.products.forEach((p) => {
                      initialGifts.push({ ...p, quantity: 0 })
                    })
                  } else {
                    initialGifts.push({ ...gift, quantity: 0 })
                  }
                })
                // setGifts(initialGifts)
                dispatch({
                  type: 'MERCHANT_PAGE',
                  payload: {
                    gifts: initialGifts,
                  },
                })
                if (products.length > 0) {
                  // console.log('set merchant',products[0].merchant)
                  // setActiveMerchant(products[0].merchant)
                  // setTitlePage(products[0].merchant.name + ' - ')
                  dispatch({
                    type: 'MERCHANT_PAGE',
                    payload: {
                      activeMerchant: products[0].merchant,
                      titlePage: products[0].merchant.name + ' - ',
                    },
                  })
                }
              } else {
                toast.error(
                  'Oops.. something wrong while loading gift data, please reload the page to try again!'
                )
              }
            })
          }
        } // end if gift id param
      } else {
        dispatch({
          type: 'SET_GIFTS',
          data: allDocs,
        })
      }
    }
    if (state.ecard && !state.gifts) {
      getGifts()
    }
  }, [dispatch, giftId, state.ecard, state.gifts])

  useEffect(() => {
    if (
      giftId &&
      state.merchantPage.gifts &&
      state.merchantPage.gifts.length > 0
    ) {
      let idx = 0
      state.merchantPage.gifts.forEach((i, ii) => {
        if (i.id === giftId) {
          idx = ii
        }
      })
      dispatch({
        type: 'MERCHANT_PAGE',
        payload: {
          activeGift: idx,
        },
      })
    }
  }, [dispatch, giftId, state.merchantPage.gifts])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  // useEffect(() => {
  //   if (state.gcardinfo && !state.ecard) {
  //     const loadEcard = async () => {
  //       let resp = await PostPublic('ecard/', {
  //         ecard: state.gcardinfo.number,
  //         key: state.key,
  //         details: '1',
  //       })
  //       // console.log('resp ecard/', resp)
  //       if (resp.status === 1) {
  //         dispatch({ type: 'SET_ECARD', data: resp.data })
  //       } else {
  //         toast.warning(resp.message)
  //         history.push('/')
  //       }
  //     }
  //     loadEcard()
  //   }
  // }, [dispatch, history, state.ecard, state.gcardinfo, state.key])

  if (!state.whitelabel || !state.gcardinfo) return <Redirect to="/" />

  // if (gifts.length === 0) return <div style={{fontSize: '28px', color: 'gray', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh'}}><FaSpinner className="icon-spin mr-2"/> Loading...</div>

  // eslint-disable-next-line eqeqeq
  const hidePrice = state.ecard && state.ecard.hide_price == 1 ? true : false

  const redeemClick = async (selectedGifts) => {
    if (!selectedGifts) {
      selectedGifts = gifts.filter((gift) => gift.quantity > 0)
    }
    // console.log('selected gifts', selectedGifts)
    if (selectedGifts.length === 0) {
      toast('Please select at least one gift to buy!', { type: 'warning' })
      return
    }

    let country = selectedGifts[0].country.code
    let currency = selectedGifts[0]['currency-code']

    // let not_evoucher = false
    // selectedGifts.forEach((gift) => {
    //   if (gift.evoucher !== '1') {
    //     not_evoucher = true
    //   }
    // });
    let no_email = false
    selectedGifts.forEach((gift) => {
      const dymethods = gift.dymethods.map((d) => d.value)
      if (!dymethods.includes('email')) no_email = true
    })

    //jika is_physical == 1  && dymethods tidak ada email show modal
    //dy method set courier
    if (no_email) {
      // setShowModal(true)
      dispatch({
        type: 'MERCHANT_PAGE',
        payload: {
          showModalDelivery: true,
        },
      })
    } else {
      // console.log('process redeem')

      // setIsRedeem(true)
      dispatch({
        type: 'MERCHANT_PAGE',
        payload: {
          isRedeem: true,
        },
      })

      // get shopping cart id
      let getShoppingcartValue = {
        marketCountry: country,
        currency: currency,
        'sales-channel': 'giftano-card-redemption',
      }

      if (state.member && state.member.id) {
        getShoppingcartValue.memberid = state.member.id
      }

      let resp = await PostPublic('get_shoppingcartid/', getShoppingcartValue)
      // console.log('shopping cart id ', resp)
      if (resp.status === 1) {
        let shoppingcartid = resp.data.id

        for (let gift of selectedGifts) {
          let respAddCartItem = await PostPublic('add_shoppingcartitem/', {
            merchantid: activeMerchant.id,
            shoppingcartid: shoppingcartid,
            dymethod: 'email',
            quantity: gift.quantity,
            vouchertype: gift.id,
          })
          // console.log('result add cart ', respAddCartItem)
          // eslint-disable-next-line eqeqeq
          if (respAddCartItem.status == 0) {
            toast(
              'Can not add gift to shopping cart, ' + respAddCartItem.message,
              { type: 'warning' }
            )
            // console.log('result add cart ', respAddCartItem)
            // setIsRedeem(false)
            dispatch({
              type: 'MERCHANT_PAGE',
              payload: {
                isRedeem: false,
              },
            })
            return
          }
        }

        //lock the cart
        let valueLock = {
          shoppingcartid: shoppingcartid,
        }
        if (state.member && state.member.id) {
          valueLock.memberid = state.member.id
        }
        await PostPublic('lock_shoppingcart/', valueLock)
        // console.log('resp lock shopping cart', respLock)

        //get ip client
        let ipClient = ''
        try {
          let respIp = await Axios('https://api.ipify.org/?format=json')
          // console.log('respIp', respIp)
          if (respIp.status === 200) {
            ipClient = respIp.data.ip
          }
        } catch (err) {}

        //pay
        let respPay = await PostVCR('createtransaction_with_ecard/', {
          ecard: state.ecard.number,
          shoppingcartid: shoppingcartid,
          ipaddress: ipClient,
        })
        // console.log('response payment', respPay)
        if (respPay.status === 1) {
          //success reload ecard
          let respEcard = await PostPublic('ecard/', {
            ecard: state.ecard.number,
            key: state.key,
            details: 1,
          })
          // console.log('resp ecard more ', resp)
          if (respEcard.status === 1) {
            dispatch({ type: 'SET_ECARD', data: respEcard.data })
          } else {
            toast.warning(
              'Something wrong while loading gift data, please reload the page to show your gift'
            )
          }
          let receipt = respPay.data
          history.push('/mygifts/' + receipt)
        } else {
          if (respPay.message) {
            toast('Unable to process redemtion, ' + respPay.message, {
              type: 'warning',
            })
          } else {
            toast(
              'Unable to process redemption, please try again or contact Gitano customer support.',
              { type: 'warning' }
            )
          }
          //console.error('error create transaction')
        }
      } else {
        if (resp.message) {
          toast('Unable to process redemption, ' + resp.message, {
            type: 'warning',
          })
        } else {
          toast(
            'Unable to process redemption, please try again or contact Gitano customer support.',
            { type: 'warning' }
          )
        }
        //console.error('error get shopping cart')
      }

      // setIsRedeem(false)
      dispatch({
        type: 'MERCHANT_PAGE',
        payload: {
          isRedeem: false,
        },
      })
    }
  }

  const grand_total =
    gifts.length > 0
      ? gifts
          .map(
            (gift) =>
              gift.quantity * parseFloat(gift.price) +
              (gift.quantity ? parseFloat(gift.primary_extrafirst) : 0)
          )
          .reduce((total, price) => total + price)
      : 0

  // console.log('gifts,', state.ecard)
  return (
    <div className="page-merchant">
      <Helmet>
        <title>{titlePage + AppConfig.title}</title>
      </Helmet>
      <div className="">
        <LoadECard>
          {state.ecard && (
            <Container className="merchant-container">
              <MyNavbar />

              {state.ecard.is_gift_box == 1 ? (
                <div className="gift-box">
                  <div className="mt-2 mb-4 d-flex justify-content-between">
                    <Button
                      className="btn-gift-control"
                      variant="clear"
                      disabled={state.merchantPage.activeGift === 0}
                      onClick={() => {
                        let nextIdx = state.merchantPage.activeGift - 1
                        if (nextIdx < 0) nextIdx = 0
                        dispatch({
                          type: 'MERCHANT_PAGE',
                          payload: {
                            activeGift: nextIdx,
                          },
                        })
                      }}
                    >
                      <FaArrowLeft className="mr-2" /> Previous gift
                    </Button>
                    <Button
                      className="btn-gift-control"
                      variant="clear"
                      disabled={
                        state.merchantPage.activeGift ===
                        state.merchantPage.gifts.length - 1
                      }
                      onClick={() => {
                        let nextIdx = state.merchantPage.activeGift + 1
                        if (nextIdx > state.merchantPage.gifts.length - 1)
                          nextIdx = state.merchantPage.gifts.length - 1
                        dispatch({
                          type: 'MERCHANT_PAGE',
                          payload: {
                            activeGift: nextIdx,
                          },
                        })
                      }}
                    >
                      Next gift <FaArrowRight className="ml-2" />
                    </Button>
                  </div>
                  {isLoading && (
                    <>
                      <CardListSkeleton
                        className="card-skeleton"
                        speed={2}
                        width={1200}
                        height={600}
                        viewBox="0 0 1200 600"
                        style={{ width: '100%' }}
                      />
                      <CardListSkeletonMobile
                        className="card-skeleton-mobile"
                        speed={2}
                        width={'100%'}
                        height={600}
                        viewBox="0 0 400 600"
                        style={{ width: '100%' }}
                      />
                    </>
                  )}
                  <Gifts />
                  <OtherGifts />
                  <ScrollToTop smooth component={<BiArrowToTop />} />
                </div>
              ) : (
                <>
                  <Row className="mb-2">
                    <Col md="12">
                      {/* <Button
                    as={Link}
                    to="/search"
                    variant="link"
                    className="btn-back-merchant"
                  >
                    <BiChevronLeft className="mr-2" />
                    Back
                  </Button> */}
                      <Breadcrumb>
                        <Breadcrumb.Item
                          linkAs={Link}
                          linkProps={{ to: '/getstarted' }}
                        >
                          {/* <Link to="/getstarted" variant="link"> */}
                          Back
                          {/* </Link> */}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                          {activeMerchant.name}
                        </Breadcrumb.Item>
                      </Breadcrumb>
                    </Col>
                  </Row>

                  {gifts.length === 0 ? (
                    <div
                      style={{
                        fontSize: '28px',
                        color: 'gray',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '50vh',
                      }}
                    >
                      <FaSpinner className="icon-spin mr-2" /> Loading...
                    </div>
                  ) : (
                    <Row>
                      <Col md="12">
                        <MerchantInfo />

                        <Row className="merchant-product__list">
                          <Col
                            md={
                              !hidePrice
                                ? 6
                                : state.ecard.is_gift_box == 1
                                ? 6
                                : 12
                            }
                          >
                            <Gifts />
                          </Col>

                          {!hidePrice ? (
                            <Col md="6">
                              <Card.Title>My cart</Card.Title>
                              <div className="cart">
                                <MyCart />
                                {/* Buy Button */}
                                <Card
                                  ref={ref}
                                  style={{
                                    boxShadow: inView
                                      ? `none`
                                      : `-1px -4px 4px -6px #3f3f3f`,
                                  }}
                                  className="card-total merchant__buy"
                                >
                                  <Card.Body>
                                    <div className="d-flex cart__container">
                                      <h4 className="mr-auto cart__key">
                                        <span className="desktop">
                                          Total Gifts Order
                                        </span>
                                        <span className="mobile">Total</span>:
                                      </h4>
                                      <h4>
                                        S$
                                        {grand_total.toLocaleString('en', {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </h4>
                                      <Button
                                        className="cart__action d-sm-block d-md-none"
                                        onClick={() => {
                                          if (
                                            grand_total > state.ecard.balance
                                          ) {
                                            let topupValue =
                                              grand_total -
                                              parseFloat(state.ecard.balance)
                                            //round up 2 digit
                                            topupValue =
                                              Math.ceil(
                                                (topupValue + Number.EPSILON) *
                                                  100
                                              ) / 100
                                            if (topupValue <= 200) {
                                              dispatch({
                                                type: 'SHOW_TOPUP',
                                                topupAmount: topupValue,
                                                topupAction: () =>
                                                  redeemClick(),
                                              })
                                            } else {
                                              toast.warning(
                                                'Your purchase exceeding maximum amount, please change to another product or contact us.'
                                              )
                                            }
                                          } else {
                                            // setShowConfirm(true)
                                            dispatch({
                                              type: 'MERCHANT_PAGE',
                                              payload: {
                                                showConfirm: true,
                                              },
                                            })
                                          }
                                        }}
                                        disabled={isRedeem || grand_total === 0}
                                      >
                                        {isRedeem && (
                                          <FaSpinner className="mr-2 icon-spin" />
                                        )}
                                        BUY
                                      </Button>
                                    </div>
                                  </Card.Body>
                                </Card>
                              </div>
                              <Button
                                className="cart__action desktop"
                                onClick={() => {
                                  if (grand_total > state.ecard.balance) {
                                    let topupValue =
                                      grand_total -
                                      parseFloat(state.ecard.balance)
                                    //round up 2 digit
                                    topupValue =
                                      Math.ceil(
                                        (topupValue + Number.EPSILON) * 100
                                      ) / 100
                                    if (topupValue <= 200) {
                                      dispatch({
                                        type: 'SHOW_TOPUP',
                                        topupAmount: topupValue,
                                        topupAction: () => redeemClick(),
                                      })
                                    }else{
                                      toast.warning(
                                        'Your purchase exceeding maximum amount, please change to another product or contact us.'
                                      )
                                    }
                                  } else {
                                    // setShowConfirm(true)
                                    dispatch({
                                      type: 'MERCHANT_PAGE',
                                      payload: {
                                        showConfirm: true,
                                      },
                                    })
                                  }
                                }}
                                disabled={isRedeem || grand_total === 0}
                              >
                                {isRedeem && (
                                  <FaSpinner className="mr-2 icon-spin" />
                                )}
                                BUY WITH GIFTANO CARD
                              </Button>
                            </Col>
                          ) : null}
                        </Row>
                      </Col>
                    </Row>
                  )}
                </>
              )}

              <ModalMerchantTnc />
              <ModalVoucherDesc />
              <ModalConfirm confirmAction={async () => {
                if(state.ecard.is_gift_box == '1' || hidePrice){
                  //get selected 
                  const selectedItem = state.merchantPage.gifts[state.merchantPage.activeGift];
                  selectedItem.quantity = 1
                  selectedItem.primary_extrafirst = await GetShippingCost(selectedItem, state.ecard.company.id);
                  redeemClick([selectedItem]);
                }else{
                  redeemClick()
                }
              }} />
              <ModalShipment />
            </Container>
          )}
        </LoadECard>
        <MyFooter />
      </div>
    </div>
  )
}

export default Merchant
