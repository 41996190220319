import React, { useContext, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FaSpinner } from 'react-icons/fa'
// import { toast } from 'react-toastify'
import { ErrorMessages } from '../components/ErrorMessages'
import { PostPublic } from '../services/Connection'
import { AppContext } from '../states'
import { SHA1 } from 'crypto-js'
import headerIconCongrats from '../assets/images/icon-receive-card.png'
import headerIconSignin from '../assets/images/icon-sign-in.png'

const ModalAddToAccount = ({ show, toggle, mode = 'default' }) => {
  const { state } = useContext(AppContext)
  const [progress, setProgress] = useState('pending')
  const hideHandler = () => {
    localStorage.setItem('invitedMember', true)
    toggle()
  }
  return (
    <Modal
      className={`account-page__modal mode-${mode}`}
      show={show}
      centered
      onHide={hideHandler}
    >
      <Modal.Header closeButton>
        {mode === 'congrats' ? (
          <>
            <img
              src={headerIconCongrats}
              alt="Congratulations"
              style={{ height: 70, width: 70, marginBottom: '15px' }}
            />
            <h4 style={{ textAlign: 'center' }}>Congratulations!</h4>
          </>
        ) : mode === 'purchase' ? (
          <>
            <img
              src={headerIconSignin}
              alt="Sign Up or Sign In"
              style={{ height: 70, width: 70, marginBottom: '15px' }}
            />
            <h4 style={{ textAlign: 'center' }}>Sign Up or Sign In!</h4>
          </>
        ) : (
          <h4>Protect your Giftano Card </h4>
        )}
      </Modal.Header>
      <Modal.Body>
        {progress === 'success' ? (
          <p className={mode === 'default' ? 'text-left' : 'text-center'}>
            Success added Giftano Card to your Giftano Account.
          </p>
        ) : mode === 'congrats' ? (
          <>
            <p className="text-center">You just received a Gift!</p>
            <p className="text-center">
              Connect to your account and easily manage all your gifts.
            </p>
          </>
        ) : mode === 'purchase' ? (
          <p>
            Connect to your account and easily manage all your gifts purchased
            with your Giftano Card.
          </p>
        ) : (
          <p>
            We recommend adding your Giftano Card to your Giftano Account so you
            can easily access and manage it.{' '}
          </p>
        )}
      </Modal.Body>
      <Modal.Footer
        className={`${
          mode === 'default' ? 'text-right' : 'text-center'
        } d-block pb-4`}
      >
        {(progress === 'success' || mode === 'default') && (
          <Button variant="light" onClick={hideHandler}>
            {progress === 'success' ? 'Close' : 'Maybe later'}
          </Button>
        )}
        {progress !== 'success' && (
          <Button
            disabled={progress === 'submitting'}
            onClick={async () => {
              if (state.session_id) {
                const number = state.ecard.number
                const session_id = state.session_id
                const ipaddress = state.ipaddress
                setProgress('submitting')
                try {
                  let res = await PostPublic('gcard/set-member/', {
                    ecard: SHA1(number).toString(),
                    session_id: session_id,
                    ipaddress: ipaddress,
                  })
                  // console.log(res)
                  if (res.status === 1) {
                    setProgress('success')
                  } else {
                    alert(res.message || ErrorMessages.error_set_member)
                    setProgress('pending')
                  }
                } catch {
                  // toast.warning(ErrorMessages.error_set_member)
                  alert(ErrorMessages.error_set_member)
                  setProgress('pending')
                }
              } else {
                const redirect_auth = process.env.REACT_APP_REDIRECT_AUTH
                const locationWindow =
                  window.location.protocol +
                  '//' +
                  window.location.host +
                  window.location.pathname +
                  '?savecard=true'
                const redirect_url =
                  redirect_auth +
                    '?redirect=' +
                    btoa(locationWindow + '|member') +
                    '&rb=' +
                    btoa(window.location.origin + '/getstarted') +
                    // eslint-disable-next-line eqeqeq
                    ((state.ecard && state.ecard.is_gift_box == 1)
                    ? '&mode=6'
                    : '&mode=3')
                window.location = redirect_url
              }
            }}
          >
            {progress === 'submitting' && (
              <FaSpinner className="icon-spin mr-2" />
            )}
            {mode === 'default' ? 'Add to Account' : 'Connect to account'}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default ModalAddToAccount
